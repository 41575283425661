import React from 'react'

import Fade from 'react-reveal/Fade'
import Section from '../../components/Section/Section'
import Grid from '../../latitude-web-ui/components/building-blocks/Grid'
import Panel from '../../latitude-web-ui/components/building-blocks/Panel'
import css from './home.module.css'

import gif from '../img/aidungeon.gif'
import ailogo from '../img/aidungeonlogo.svg'

function GamesSection() {
  return (
    <Fade bottom distance="24px" delay={400}>
      <Section label="games">
        <Grid columns={1}>
          <Panel
            span={1}
            style={{
              borderRadius: 'var(--extraSmall)',
              backgroundImage: `url(${gif})`,
              backgroundPosition: 'bottom right',
              position: 'relative',
            }}
            hover
            className={css['game-card-selectable']}
          >
            <a
              href="https://play.aidungeon.io/main/home"
              target="_blank"
              rel="noreferrer"
            >
              <div className={css['game-card-sizer']} />

              <div className={css['game-card-container']}>
                <div className={css.inner}>
                  <img
                    src={ailogo}
                    alt="ai dungeon logo"
                    style={{ height: '16%' }}
                  />
                  <p>
                    Our first AI installment. You enter your actions and the AI
                    will continue the story.
                  </p>
                  <a
                    href="https://play.aidungeon.io/main/home"
                    target="_blank"
                    rel="noreferrer"
                  >
                    play free
                  </a>
                </div>
              </div>
            </a>
          </Panel>
        </Grid>
      </Section>
    </Fade>
  )
}

export default GamesSection
