import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { latitudeApiUrl } from '../../config/LatitudeApi'

import Button from '../../latitude-web-ui/components/building-blocks/controls/Button'
import ErrorComponent from '../../latitude-web-ui/components/building-blocks/controls/Error'
import Success from '../../latitude-web-ui/components/building-blocks/controls/Success'
import TextInput from '../../latitude-web-ui/components/building-blocks/controls/TextInput'
import Layout from '../../latitude-web-ui/components/building-blocks/Layout'
import css from '../../latitude-web-ui/components/building-blocks/main.module.css'
import Text from '../../latitude-web-ui/components/building-blocks/Text'

function PasswordForgot() {
  function useParams() {
    return new URLSearchParams(useLocation().search)
  }
  const params = useParams()

  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [email, setEmail] = useState(null)
  const [message, setMessage] = useState(null)

  const onEmailChange = (value) => {
    setError(false)
    setMessage(null)
    setEmail(value)
  }

  const onPasswordForgot = async (e) => {
    e.preventDefault()

    try {
      const response = await fetch(`${latitudeApiUrl}/user/passwordForgot`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          grantId: params.get('grantId'),
          returnTo: decodeURIComponent(params.get('returnTo')),
          v2: params.get('v2') || false,
          email,
        }),
      })

      const json = await response.json()

      if (!response.ok) {
        setError(true)
        setMessage(json.error.message)
        return
      }

      setSuccess(true)
      setMessage(json.message)
    } catch (err) {
      setError(true)
      setMessage('Server error.  Try again later.')
    }
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function ErrorMessage(msg) {
    return <ErrorComponent>{msg}</ErrorComponent>
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function SuccessMessage(msg) {
    return (
      <Success>
        <div style={{ flexDirection: 'column' }}>{msg}</div>
      </Success>
    )
  }

  return (
    <form onSubmit={onPasswordForgot}>
      <Layout
        center
        vertical
        style={{
          height: '100vh',
          backgroundImage: `url('https://latitude-web-images.s3.us-east-2.amazonaws.com/account-bg.jpg')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Layout
          vertical
          gap="var(--c2)"
          className={`${css['modal-panel']} ${css.alt}`}
        >
          <Text type="header" size="large" style={{ textAlign: 'center' }}>
            Forgot Password
          </Text>
          <br />
          {!success && (
            <TextInput
              name="email"
              value={email}
              onChange={onEmailChange}
              transparent
              placeholder="Email"
              required
            />
          )}
          {error && ErrorMessage(message)}
          {success && SuccessMessage(message)}
          {!success && (
            <Button
              accessibilityLabel="Submit Email"
              label="Submit"
              size="large"
              type="submit"
            />
          )}
        </Layout>
      </Layout>
    </form>
  )
}

export default PasswordForgot
