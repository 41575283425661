/* eslint-disable react/prop-types */
import React from 'react'
import { useLocation } from 'react-router'
import Button from '../../latitude-web-ui/components/building-blocks/controls/Button'
import Layout from '../../latitude-web-ui/components/building-blocks/Layout'
import AuthPip from './AuthPip'
import Apple from './apple.png'
import Discord from './discord.png'
import Google from './google.png'
// import Steam from "./steam.png";
import Twitch from './twitch.png'
import { latitudeApiUrl } from '../../config/LatitudeApi'

function ThirdPartyAuth({ selectEmail, primaryMethod }) {
  const params = new URLSearchParams(useLocation().search)
  // eslint-disable-next-line react/no-unstable-nested-components
  function Or() {
    return (
      <Layout centerY>
        <hr style={{ flex: 1, maxHeight: 1, borderColor: 'var(--dark-3)' }} />
        <p style={{ margin: 'var(--small)' }}> or </p>
        <hr style={{ flex: 1, maxHeight: 1, borderColor: 'var(--dark-3)' }} />
      </Layout>
    )
  }
  const loginMethodMap = {
    email: {
      onClick: () => {
        selectEmail(true)
      },
      icon: null,
    },
    // steam: {
    //   onClick: () => { document.getElementById('steam-form').submit() },
    //   icon: Steam,
    // },
    discord: {
      onClick: () => {
        window.location = `https://discord.com/api/oauth2/authorize?client_id=915307003381964880&state=${encodeURIComponent(
          params.get('grantId')
        )}&response_type=code&redirect_uri=${encodeURIComponent(
          latitudeApiUrl
        )}/user/auth/discord&scope=identify%20email`
      },
      icon: Discord,
    },
    google: {
      onClick: () => {
        window.location = `https://accounts.google.com/o/oauth2/v2/auth?client_id=886879764674-fhjvslb1i91a0mbu98obicn03qjloond.apps.googleusercontent.com&state=${encodeURIComponent(
          params.get('grantId')
        )}&response_type=code&redirect_uri=${encodeURIComponent(
          latitudeApiUrl
        )}/user/auth/google&scope=email`
      },
      icon: Google,
    },
    twitch: {
      onClick: () => {
        window.location = `https://id.twitch.tv/oauth2/authorize?client_id=nkry0zl0lbcd0lj6k670ku83cxp7nl&state=${encodeURIComponent(
          params.get('grantId')
        )}&redirect_uri=${encodeURIComponent(
          latitudeApiUrl
        )}/user/auth/twitch&response_type=code&scope=${encodeURIComponent(
          'user:read:email'
        )}`
      },
      icon: Twitch,
    },
    apple: {
      onClick: () => {
        window.location = `https://appleid.apple.com/auth/authorize?client_id=io.latitude.api&redirect_uri=${encodeURIComponent(
          `${latitudeApiUrl}/user/auth/apple`
        )}&response_type=code&response_mode=form_post&scope=email&state=${encodeURIComponent(
          params.get('grantId')
        )}`
      },
      icon: Apple,
    },
  }
  return (
    <>
      <Button
        type="secondary"
        size="large"
        label={
          primaryMethod === 'email' ? (
            'email'
          ) : (
            <Layout gap="var(--extraSmall)">
              <img
                src={loginMethodMap[primaryMethod].icon}
                style={{ height: 24, width: 24 }}
                alt={primaryMethod}
              />
              {primaryMethod}
            </Layout>
          )
        }
        iconName={primaryMethod === 'email' && 'email'}
        onPress={() => {
          if (primaryMethod === 'email') selectEmail(true)
        }}
      />
      <Or />
      <Layout gap="var(--small)">
        {Object.keys(loginMethodMap).map((key) => {
          if (key === primaryMethod) return null
          return <AuthPip type={key} onClick={loginMethodMap[key].onClick} />
        })}
      </Layout>
      <form
        action="https://steamcommunity.com/openid/login"
        method="post"
        id="steam-form"
      >
        <input
          type="hidden"
          name="openid.identity"
          value="http://specs.openid.net/auth/2.0/identifier_select"
        />
        <input
          type="hidden"
          name="openid.claimed_id"
          value="http://specs.openid.net/auth/2.0/identifier_select"
        />
        <input
          type="hidden"
          name="openid.ns"
          value="http://specs.openid.net/auth/2.0"
        />
        <input type="hidden" name="openid.mode" value="checkid_setup" />
        <input type="hidden" name="openid.realm" value={latitudeApiUrl} />
        <input
          type="hidden"
          name="openid.return_to"
          value={`${latitudeApiUrl}/user/auth/steam`}
        />
      </form>
    </>
  )
}

export default ThirdPartyAuth
