/* eslint-disable react/prop-types */
import React from 'react'
import Icon from 'material-icons-react'
import css from '../Home/home.module.css'
import Apple from './apple.png'
import Discord from './discord.png'
import Google from './google.png'
import Steam from './steam.png'
import Twitch from './twitch.png'

function AuthPip({ type, onClick }) {
  return (
    <button
      type="button"
      style={{
        padding: 0,
        margin: 0,
        backgroundColor: 'transparent',
        border: 0,
        display: 'flex',
        flex: 1,
      }}
      onClick={onClick}
    >
      <div className={css['auth-pip']}>
        {type === 'google' && (
          <img src={Google} alt="Google" title="Login with Google" />
        )}
        {type === 'apple' && (
          <img src={Apple} alt="Apple" title="Login with Apple" />
        )}
        {type === 'discord' && (
          <img src={Discord} alt="Discord" title="Login with Discord" />
        )}
        {type === 'steam' && (
          <img src={Steam} alt="Steam" title="Login with Steam" />
        )}
        {type === 'twitch' && (
          <img src={Twitch} alt="Twitch" title="Login with Twitch" />
        )}
        {type === 'email' && <Icon icon="email" color="#ffffff" />}
      </div>
    </button>
  )
}

export default AuthPip
