import React from 'react'

import Fade from 'react-reveal/Fade'
import Section from '../../components/Section/Section'

function PrivacyPolicy() {
  return (
    <div>
      <Fade bottom distance="24px">
        <Section dark>
          <div className="article">
            <h1>Privacy Policy</h1>
            <p>
              This Privacy Policy describes how Oasis Tech, Inc. (doing business
              as Latitude) (“Company”, “we”, “us” “our”) collects, uses and
              discloses information about users of the Company&apos;s websites
              (aidungeon.io, play.aidungeon.io, voyageplay.ai, and latitude.io),
              mobile applications, online services, tools and features
              (collectively, the “Services”). For the purposes of this Privacy
              Policy, “you” and “your” means you as the user of the Services.
            </p>
            <p>
              PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY USING, ACCESSING, OR
              DOWNLOADING ANY OF THE SERVICES, YOU AGREE TO THE USE OF YOUR
              INFORMATION IT DESCRIBES AND TO THE OTHER TERMS OF THIS PRIVACY
              POLICY. IF YOU DO NOT AGREE TO THIS PRIVACY POLICY, PLEASE DO NOT
              ACCESS AND USE THE SERVICES.
            </p>
            <br />
            <h2>Updating This Privacy Policy</h2>
            <p>
              We may modify this Privacy Policy from time to time in which case
              we will update the “Last Revised” date at the top of this Privacy
              Policy. If we make changes that are material, we will use
              reasonable efforts to attempt to provide notice to you and, where
              required by applicable law, we will obtain your consent. Notice
              may be by email to you at the last email address you provided us,
              by posting notice of such changes on the Services, or by other
              means, consistent with applicable law. However, it is your sole
              responsibility to review the Privacy Policy from time to time to
              view any such changes. The updated Privacy Policy will be
              effective as of the time of posting, or such later date as may be
              specified in the updated Privacy Policy. IF YOU DO NOT ACCEPT AND
              AGREE TO THIS PRIVACY POLICY, INCLUDING ANY CHANGES, THEN YOU MUST
              NOT ACCESS OR USE THE SERVICES.
            </p>
            <br />
            <h2>Company&apos;s Collection and Use of Information</h2>
            <p>
              In order to provide you with particular services, we may ask you
              to provide us with certain details or information about you.
              Information that you submit through our Services may include:
              <br />
              <br />
              Basic contact details, including your name and email address. We
              collect basic contact details to register and service your
              account, to communicate with you and to inform you about products
              or promotional offers. Account information, including your
              username and password. We collect account information to register,
              maintain and secure your account with us. If you choose to use the
              Services and register an account, you are responsible for keeping
              your account credentials safe. We highly recommend that you do not
              share your username, password, or other access details with anyone
              else. If you believe your account has been compromised, please
              contact us immediately. Any other information you choose to
              include in communications with us, for example, when sending a
              message through the Contact Us web form. Some features on the
              Services may require you to enter certain personal information in
              order to submit the form. You may elect not to provide this
              information, but doing so may prevent you from using or accessing
              these features. We also automatically collect certain information
              about your interaction with the Services (“Usage Data”). To do
              this, we may use cookies, search data, user input or third party
              analytics services. Usage Data may include:
              <br />
              <br />
              Unique device identifier; Device type, such as your phone,
              computer, or tablet; IP address; Browser type; Date and time
              stamps, such as the date and time you first accessed the Services;
              Operating system; Log data; and Other information regarding your
              interaction with the Services, such as clickstream data and ad
              impressions. We use the information we collect automatically to
              improve our products and services, customize your experience,
              analyze trends, track user movements and gather statistical
              information for aggregate use.
              <br />
              <br />
              In addition to the foregoing, we may use all of the above
              information to comply with any applicable legal obligations, to
              enforce any applicable terms of service, and to protect or defend
              the Services, our rights, the rights of our users, or others.
            </p>
            <br />
            <h2>How the Company Shares Your Information</h2>
            <p>
              In certain circumstances, the Company may share your information
              with third parties. Such circumstances may include:
              <br />
              <br />
              With vendors or other service providers, such as payment
              processors, data analytics vendors, and data security vendors; To
              comply with applicable law or any obligations thereunder,
              including cooperation with law enforcement, judicial orders, and
              regulatory inquiries; In connection with an asset sale, merger,
              bankruptcy, or other business transaction; To enforce any
              applicable Terms of Service; To ensure the safety and security of
              the Company and/or its users; When you request us to share certain
              information with third parties, such as through your use of social
              media widgets or login integrations; and With professional
              advisors, such as auditors, law firms, or accounting firms. You
              acknowledge that such sharing of information may occur in all of
              the aforementioned circumstances and is permitted by and subject
              to this Privacy Policy.
            </p>
            <br />
            <h2>Cookies and Other Tracking Technologies</h2>
            <p>
              Do Not Track Signals Your browser settings may also allow you to
              transmit a “Do Not Track” signal when you visit various websites.
              Like many websites, our website is not designed to respond to “Do
              Not Track” signals received from browsers. To learn more about “Do
              Not Track” signals, you can visit http://www.allaboutdnt.com/.
              Cookies and Other Tracking Technologies You may control the way in
              which your devices permit the use of Tracking Technologies. If you
              so choose, you may block or delete our cookies from your browser;
              however, blocking or deleting cookies may cause some of the
              Services, including any portal features and general functionality,
              to work incorrectly. Most browsers accept cookies automatically.
              However, you may be able to configure your browser settings to use
              the Services without some cookie functionality. You can delete
              cookies manually or set your browser to automatically delete
              cookies on a predetermined schedule.
              <br />
              <br />
              We may also use Flash cookies (also known as “persistent
              identification elements” or “local shared objects”) on certain
              pages. Because Flash cookies cannot be controlled through your
              browser settings, you may click here to adjust your preferences.
              You can also identify Flash cookies running on your computer by
              visiting the Flash Player folder. Flash cookies, or LSO files, are
              typically stored with a “.SOL” extension. Please note that if you
              block cookies, some functions otherwise available on the Services
              may be unavailable, and we may not be able to present you with
              personally-tailored content. If you have questions regarding the
              specific information about you that we process or retain, as well
              as your choices regarding our collection and use practices, please
              contact us using the information listed below.
            </p>
            <br />
            <h2>User Generated Content</h2>
            <p>
              The Services also host user comments, game interactions, and
              shared game content, which users may elect to join and/or
              participate (“User-Generated Content” or “UGC”). We or others may
              store, display, reproduce, publish, or otherwise use UGC, and may
              or may not attribute it to you. Others may also have access to UGC
              and may have the ability to share it with third parties. If you
              choose to submit UGC to any public area of the Services, your UGC
              will be considered “public” and will be accessible by anyone,
              including the Company. Please note that we do not control who will
              have access to the information that you choose to make available
              to others, and cannot ensure that parties who have access to such
              information will respect your privacy or keep it secure. We are
              not responsible for the privacy or security of any information
              that you make publicly available on the features permitting
              creation of UGC or what others do with information you share with
              them on such platforms. We are not responsible for the accuracy,
              use or misuse of any UGC that you disclose or receive from third
              parties through the forums or email lists.
            </p>
            <br />
            <h2>Children&apos;s Privacy</h2>
            <p>
              Users must be 18 or older to use the Services. Further, we do not
              seek or knowingly collect any personal information about children
              under 13 years of age. If we become aware that we have unknowingly
              collected information about a child under 13 years of age, we will
              make commercially reasonable efforts to delete such information
              from our database.
            </p>
            <br />
            <h2>Data Security</h2>
            <p>
              Please note that any information you send to us electronically,
              while using the Services or otherwise interacting with us, may not
              be secure when it is transmitted to us. We recommend that you do
              not use unsecure channels to communicate sensitive or confidential
              information to us. Please be aware though that, despite our best
              efforts, no security measures are perfect or impenetrable, and we
              cannot guarantee “perfect security.” Any information you send us
              through any means is transmitted at your own risk.
            </p>
            <br />
            <h2>Your California Privacy Rights</h2>
            <p>
              We do not share personal information as defined by California
              Civil Code Section 1798.83 (“Shine The Light Law”) with third
              parties for their direct marketing purposes.
            </p>
            <br />
            <h2>How to Contact Us</h2>
            <p>
              Should you have any questions about our privacy practices or this
              Privacy Policy, please email us at contact-us@latitude.io or
              contact us at 8 The Green, Suite B, Dover, DE 19901 and/or (801)
              203-0065.
            </p>
          </div>
        </Section>
      </Fade>
    </div>
  )
}

export default PrivacyPolicy
