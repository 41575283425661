import React from 'react'

import Fade from 'react-reveal/Fade'
import css from './hero.module.css'
import video from './hero.mp4'

function HeroSection() {
  return (
    <div className={css['hero-container']}>
      <video
        autoPlay
        muted
        loop
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          objectFit: 'cover',
        }}
      >
        <source src={video} type="video/mp4" />
      </video>
      <div className={css['hero-content']}>
        <div className="latitude-web-wrapper">
          <Fade bottom distance="24px">
            <h1>
              The future of <br />
              AI-generated games <div className={css['cursor-blink']}>_</div>
            </h1>
          </Fade>
          <Fade bottom distance="24px" delay={200}>
            <p>
              We’re making AI a tool of creativity and freedom for everyone.
            </p>
          </Fade>
        </div>
      </div>
    </div>
  )
}

export default HeroSection
