/* eslint-disable react/prop-types */
import React from 'react'

import css from '../main.module.css'

function Success({ children }) {
  return (
    <div className={css['success-container']}>
      <div className={css.success}>{children}</div>
    </div>
  )
}

export default Success
