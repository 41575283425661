import React from 'react'
import { useLocation } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

import css from './navigation-bar.module.css'
import logo from './logo.svg'
// import ailogo from "../../screens/img/aidungeonlogo.svg"

function NavigationBar() {
  const location = useLocation()
  const accountManagement = location.pathname.indexOf('account') > 0
  return (
    <div className={css['navigation-bar-container']}>
      <div className={css['navigation-inner']}>
        {accountManagement && (
          <div className={css['navigation-bar-section']}>
            <HashLink smooth to="/#top">
              <img src={logo} alt="logo" className={css.logo} />
            </HashLink>
          </div>
        )}
        {!accountManagement && (
          <>
            <div className={css['navigation-bar-section']}>
              <HashLink smooth to="/#top">
                <img src={logo} alt="logo" className={css.logo} />
              </HashLink>
            </div>
            <div className={css['navigation-bar-section']}>
              <HashLink smooth to="/#games">
                <div className={css['nav-link']}>GAMES</div>
              </HashLink>

              <HashLink smooth to="/#news">
                <div className={css['nav-link']}>NEWS</div>
              </HashLink>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default NavigationBar
