/* eslint-disable react/prop-types */
import React from 'react'

import styles from './main.module.css'

function Grid({ columns, sidebar, fullHeight, style, className, ...props }) {
  const newColumns = !columns ? 4 : columns
  return (
    <div
      className={`${styles[`arcade-ui-grid-${newColumns}`]} ${className}`}
      style={{ ...style }}
    >
      {props.children}
    </div>
  )
}

export default Grid
