/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import Button from '../../latitude-web-ui/components/building-blocks/controls/Button'
import TextInput from '../../latitude-web-ui/components/building-blocks/controls/TextInput'
import Layout from '../../latitude-web-ui/components/building-blocks/Layout'
import css from '../../latitude-web-ui/components/building-blocks/main.module.css'
import Text from '../../latitude-web-ui/components/building-blocks/Text'
import Checkbox from '../../latitude-web-ui/components/building-blocks/controls/Checkbox'
import ErrorComponent from '../../latitude-web-ui/components/building-blocks/controls/Error'
import { latitudeApiUrl } from '../../config/LatitudeApi'
import ThirdPartyAuthV2 from './ThirdPartyAuthV2'
import { constructLoginV2Url } from '../../helpers/urlConstructor'

function JoinV2() {
  function useParams() {
    return new URLSearchParams(useLocation().search)
  }
  const params = useParams()
  const [emailSelected, selectEmail] = useState(params.get('auth') === 'email')
  const [emailOptIn, setEmailOptIn] = useState(true)
  // eslint-disable-next-line react/no-unstable-nested-components
  function Message(msg) {
    if (!msg) return null
    return <ErrorComponent>{msg}</ErrorComponent>
  }

  const handleSubmit = (event) => {
    const isSubmitting = event.target.getAttribute('submitting')
    if (isSubmitting) {
      event.preventDefault()
      return false
    }
    event.target.setAttribute('submitting', true)
    setTimeout(() => {
      event.target.removeAttribute('submitting')
    }, 5000)
    return true
  }

  return (
    <form
      onSubmit={handleSubmit}
      action={`${latitudeApiUrl}/user/join`}
      method="post"
    >
      <input type="hidden" name="grantId" value={params.get('grantId')} />
      <input
        type="hidden"
        name="returnTo"
        value={decodeURIComponent(params.get('returnTo') || '')}
      />
      <input type="hidden" name="useAuthV2" value="true" />
      <Layout
        center
        vertical
        style={{
          height: '100vh',
          backgroundImage: `url('https://latitude-web-images.s3.us-east-2.amazonaws.com/account-bg.jpg')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Layout
          vertical
          gap="var(--small)"
          className={`${css['modal-panel']} ${css.alt}`}
          style={{ textAlign: 'center' }}
        >
          <div style={{ textAlign: 'center' }}>
            <Text
              type="header"
              size="large"
              style={{ marginBottom: 'var(--c2)' }}
            >
              Join Latitude
            </Text>
            <Text
              type="body"
              size="medium"
              style={{ marginBottom: 'var(--c2)' }}
            >
              Join to access {params.get('referrer') === 'lab' && 'the Lab, '}{' '}
              AI Dungeon and more.
            </Text>
          </div>
          {!emailSelected ? (
            <>
              <ThirdPartyAuthV2
                primaryMethod="email"
                selectEmail={selectEmail}
              />
              {Message(params.get('msg'))}

              <Text style={{ textAlign: 'center' }} type="body" size="small">
                Already have an account? <br />
              </Text>

              <Link to={constructLoginV2Url(params)}>
                <div className="link">Sign in</div>
              </Link>
            </>
          ) : (
            <>
              <TextInput
                name="email"
                transparent
                placeholder="Email"
                onChangeText={() => {}}
              />
              <TextInput
                name="username"
                transparent
                placeholder="Username"
                onChangeText={() => {}}
              />
              <TextInput
                name="password"
                transparent
                password
                placeholder="Password"
                onChangeText={() => {}}
              />
              <div
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Checkbox
                  label="Opt in to email"
                  type="checkbox"
                  checked={emailOptIn}
                  onChange={() => setEmailOptIn(!emailOptIn)}
                />
                <label
                  style={{ alignItems: 'center', margin: 'auto' }}
                  htmlFor="optInEmail"
                >
                  Receive Game Updates from Voyage.
                </label>
              </div>
              {Message(params.get('msg'))}
              <Button
                type="submit"
                label="Continue"
                size="large"
                style={{ marginTop: 'var(--c2)', marginBottom: 'var(--c2)' }}
              />
              <Button
                label="Back"
                size="large"
                type="blank"
                onPress={() => {
                  selectEmail(false)
                }}
              />
            </>
          )}
        </Layout>
      </Layout>
    </form>
  )
}
export default JoinV2
