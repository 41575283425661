import React from 'react'
import { useLocation, Link } from 'react-router-dom'

import Section from '../Section/Section'
import Grid from '../../latitude-web-ui/components/building-blocks/Grid'
import Panel from '../../latitude-web-ui/components/building-blocks/Panel'

import css from './footer.module.css'

import logo from '../../screens/img/logo-white.svg'

function Footer() {
  const date = new Date()
  const location = useLocation()
  const accountManagement = location.pathname.indexOf('account') > 0
  if (!accountManagement) return null
  return (
    <Section dark>
      <Grid columns={4} style={{ paddingTop: 'var(--extraLarge)' }}>
        <Panel noShadow noBorder span={2} style={{ borderRadius: 0 }}>
          <img
            src={logo}
            alt="latitude logo"
            style={{ height: 'var(--small)' }}
          />
          <p> © {date.getFullYear()} Latitude </p>
        </Panel>
        <Panel
          noShadow
          noBorder
          span={2}
          style={{
            borderRadius: 0,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Link to="/contact">
            <div className={css['footer-link']}>CONTACT</div>
          </Link>
          <Link to="/privacy-policy">
            <div className={css['footer-link']}>PRIVACY POLICY</div>
          </Link>
          <Link to="/TOS">
            <div className={css['footer-link']}>TERMS OF SERVICE</div>
          </Link>
        </Panel>
      </Grid>
    </Section>
  )
}

export default Footer
