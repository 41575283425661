import dotenv from 'dotenv'

dotenv.config()

const {
  REACT_APP_NODE_ENV,
  REACT_APP_LATITUDE_API_URL,
  REACT_APP_LATITUDE_API_URL_DEV,
} = process.env

// eslint-disable-next-line import/prefer-default-export
export const latitudeApiUrl =
  REACT_APP_NODE_ENV === 'development'
    ? REACT_APP_LATITUDE_API_URL_DEV
    : REACT_APP_LATITUDE_API_URL || 'https://api.latitude.io'
