/* eslint-disable react/prop-types */
import React from 'react'

import styles from '../main.module.css'

function Checkbox({ checked, onChange, name }) {
  return (
    <input
      name={name}
      type="checkbox"
      className={styles.checkbox}
      checked={checked}
      onChange={onChange}
    />
  )
}

export default Checkbox
