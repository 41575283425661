/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import Button from '../../latitude-web-ui/components/building-blocks/controls/Button'
import TextInput from '../../latitude-web-ui/components/building-blocks/controls/TextInput'
import Layout from '../../latitude-web-ui/components/building-blocks/Layout'
import css from '../../latitude-web-ui/components/building-blocks/main.module.css'
import Text from '../../latitude-web-ui/components/building-blocks/Text'
import Checkbox from '../../latitude-web-ui/components/building-blocks/controls/Checkbox'
import ErrorComponent from '../../latitude-web-ui/components/building-blocks/controls/Error'
import { latitudeApiUrl } from '../../config/LatitudeApi'

function JoinInfoV2() {
  const [emailOptIn, setEmailOptIn] = useState(true)
  function useParams() {
    return new URLSearchParams(useLocation().search)
  }
  const params = useParams()
  // eslint-disable-next-line react/no-unstable-nested-components
  function Message(msg) {
    if (!msg) return null
    return <ErrorComponent>{msg}</ErrorComponent>
  }

  const handleSubmit = (event) => {
    const isSubmitting = event.target.getAttribute('submitting')
    if (isSubmitting) {
      event.preventDefault()
      return false
    }
    event.target.setAttribute('submitting', true)
    setTimeout(() => {
      event.target.removeAttribute('submitting')
    }, 5000)
    return true
  }

  return (
    <form
      onSubmit={handleSubmit}
      action={`${latitudeApiUrl}/user/auth/v2/join`}
      method="post"
    >
      <input type="hidden" name="grantId" value={params.get('grantId')} />
      <input
        type="hidden"
        name="returnTo"
        value={decodeURIComponent(params.get('returnTo') || '')}
      />
      <Layout
        center
        vertical
        style={{
          height: '100vh',
          backgroundImage: `url('https://latitude-web-images.s3.us-east-2.amazonaws.com/account-bg.jpg')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Layout
          vertical
          gap="var(--c1)"
          className={`${css['modal-panel']} ${css.alt}`}
          style={{ textAlign: 'center' }}
        >
          <div style={{ textAlign: 'center' }}>
            <Text
              type="header"
              size="large"
              style={{ marginBottom: 'var(--c2)' }}
            >
              Welcome to Latitude
            </Text>
            <Text
              type="body"
              size="medium"
              style={{ marginBottom: 'var(--c2)' }}
            >
              Create a username for all Latitude games
            </Text>
          </div>
          {Message(params.get('msg'))}
          <TextInput
            name="username"
            transparent
            placeholder="Username"
            onChangeText={() => {}}
          />
          <div style={{ textAlign: 'center' }}>
            <Checkbox
              name="emailOptIn"
              label="Opt in to email"
              type="checkbox"
              checked={emailOptIn}
              onChange={() => {
                setEmailOptIn(!emailOptIn)
              }}
            />
            <label style={{ alignItems: 'center' }} htmlFor="optInEmail">
              Receive Game Updates from Voyage.
            </label>
          </div>
          <Button
            type="submit"
            label="Enter"
            size="large"
            style={{ marginTop: 'var(--c2)', marginBottom: 'var(--c2)' }}
          />
        </Layout>
      </Layout>
    </form>
  )
}

export default JoinInfoV2
