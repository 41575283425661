import React, { useState } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { latitudeApiUrl } from '../../config/LatitudeApi'
import { latitudeAppUrl } from '../../config/LatitudeApp'
import {
  constructLoginUrl,
  constructLoginV2Url,
} from '../../helpers/urlConstructor'

import Button from '../../latitude-web-ui/components/building-blocks/controls/Button'
import ErrorComponent from '../../latitude-web-ui/components/building-blocks/controls/Error'
import Success from '../../latitude-web-ui/components/building-blocks/controls/Success'
import TextInput from '../../latitude-web-ui/components/building-blocks/controls/TextInput'
import Layout from '../../latitude-web-ui/components/building-blocks/Layout'
import css from '../../latitude-web-ui/components/building-blocks/main.module.css'
import Text from '../../latitude-web-ui/components/building-blocks/Text'

function PasswordReset() {
  function useParams() {
    return new URLSearchParams(useLocation().search)
  }
  const params = useParams()

  const history = useHistory()

  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [password, setPassword] = useState(null)
  const [message, setMessage] = useState(null)

  const onPasswordChange = (value) => {
    setError(false)
    setMessage(null)
    setPassword(value)
  }

  const handleErrorMessage = (err) => {
    if (err.message.includes('Invalid or expired code'))
      return setMessage(err.message)
    return setMessage('Server error.  Try again later.')
  }

  const onPasswordReset = async (e) => {
    e.preventDefault()

    try {
      const response = await fetch(`${latitudeApiUrl}/user/passwordReset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          returnTo:
            decodeURIComponent(params.get('returnTo')) || latitudeAppUrl,
          code: params.get('code') || '',
          password,
        }),
      })

      if (response.status !== 200) {
        throw Error(response.statusText)
      }
      const json = await response.json()

      if (!response.ok) {
        setError(true)
        setMessage(json.error.message)
        return
      }

      setSuccess(true)
      setMessage(json.message)

      setTimeout(() => {
        const v2 = JSON.parse(params.get('v2'))
        const path = v2
          ? constructLoginV2Url(params)
          : constructLoginUrl(params)

        history.push(path)
      }, 2000)
    } catch (err) {
      setError(true)
      handleErrorMessage(err)
    }
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function ErrorMessage(msg) {
    return <ErrorComponent>{msg}</ErrorComponent>
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function SuccessMessage(msg) {
    return (
      <Success>
        <div style={{ flexDirection: 'column' }}>{msg}</div>
      </Success>
    )
  }

  return (
    <form onSubmit={onPasswordReset}>
      <Layout
        center
        vertical
        style={{
          height: '100vh',
          backgroundImage: `url('https://latitude-web-images.s3.us-east-2.amazonaws.com/account-bg.jpg')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Layout
          vertical
          gap="var(--c2)"
          className={`${css['modal-panel']} ${css.alt}`}
        >
          <Text type="header" size="large" style={{ textAlign: 'center' }}>
            Reset Password
          </Text>
          <br />
          {!success && (
            <TextInput
              name="password"
              value={password}
              onChange={onPasswordChange}
              password
              transparent
              placeholder="Password"
              required
            />
          )}
          {error && ErrorMessage(message)}
          {success && SuccessMessage(message)}
          {!success && (
            <Button
              accessibilityLabel="Reset Password"
              label="Reset Password"
              size="large"
              type="submit"
            />
          )}
          <Text style={{ textAlign: 'center' }} type="body" size="small">
            <Link
              to={
                JSON.parse(params.get('v2'))
                  ? constructLoginV2Url(params)
                  : constructLoginUrl(params)
              }
            >
              <div className="link">Go to Login</div>
            </Link>
          </Text>
        </Layout>
      </Layout>
    </form>
  )
}

export default PasswordReset
