import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { latitudeApiUrl } from '../../config/LatitudeApi'
import { latitudeAppUrl } from '../../config/LatitudeApp'
import { constructForgotPasswordUrl } from '../../helpers/urlConstructor'
import sendServerEvent from '../../hooks/Events'

import Button from '../../latitude-web-ui/components/building-blocks/controls/Button'
import ErrorComponent from '../../latitude-web-ui/components/building-blocks/controls/Error'
import TextInput from '../../latitude-web-ui/components/building-blocks/controls/TextInput'
import Layout from '../../latitude-web-ui/components/building-blocks/Layout'
import css from '../../latitude-web-ui/components/building-blocks/main.module.css'
import Text from '../../latitude-web-ui/components/building-blocks/Text'

function Message(msg) {
  if (!msg) return null
  return (
    <ErrorComponent>
      <div style={{ flexDirection: 'column' }}>
        {msg}
        {msg === 'Invalid password' && (
          <div className="link">Forgot password?</div>
        )}
      </div>
    </ErrorComponent>
  )
}

function LoginLinkAccount() {
  function useParams() {
    return new URLSearchParams(useLocation().search)
  }
  const params = useParams()

  const handleSubmit = (event) => {
    const isSubmitting = event.target.getAttribute('submitting')
    if (isSubmitting) {
      event.preventDefault()
      return false
    }
    event.target.setAttribute('submitting', true)
    sendServerEvent({
      eventName: 'link_existing_account_submitted',
      variation: JSON.stringify({
        values: event?.target,
      }),
    })
    setTimeout(() => {
      event.target.removeAttribute('submitting')
    }, 5000)
    return true
  }

  return (
    <form
      onSubmit={handleSubmit}
      action={`${latitudeApiUrl}/user/login/link`}
      method="post"
      style={{ overflow: 'hidden' }}
    >
      <input
        type="hidden"
        name="returnTo"
        value={decodeURIComponent(
          params.get('returnTo') || `${latitudeAppUrl}`
        )}
      />
      <input type="hidden" name="grantId" value={params.get('grantId') || ''} />
      <Layout
        center
        vertical
        style={{
          height: '100vh',
          backgroundImage: `url('https://latitude-web-images.s3.us-east-2.amazonaws.com/account-bg.jpg')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          overflow: 'hidden',
        }}
      >
        <Layout
          vertical
          gap="var(--c2)"
          className={`${css['modal-panel']} ${css.alt}`}
        >
          <Text type="header" size="large">
            Sign in to link your account
          </Text>
          <br />
          <TextInput
            name="login"
            transparent
            placeholder="Username or Email"
            required
          />
          <TextInput
            name="password"
            password
            transparent
            placeholder="Password"
            required
          />
          {Message(params.get('msg'))}
          <Button
            accessibilityLabel="Login"
            label="Sign in"
            size="large"
            type="submit"
          />
          <Text style={{ textAlign: 'center' }} type="body" size="small">
            <Link to={constructForgotPasswordUrl(params, true)}>
              <div className="link">Forgot your password?</div>
            </Link>
          </Text>
        </Layout>
      </Layout>
    </form>
  )
}

export default LoginLinkAccount
