import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { latitudeApiUrl } from '../../config/LatitudeApi'
import { latitudeAppUrl } from '../../config/LatitudeApp'
import {
  constructForgotPasswordUrl,
  constructJoinUrl,
} from '../../helpers/urlConstructor'

import Button from '../../latitude-web-ui/components/building-blocks/controls/Button'
import ErrorComponent from '../../latitude-web-ui/components/building-blocks/controls/Error'
import TextInput from '../../latitude-web-ui/components/building-blocks/controls/TextInput'
import Layout from '../../latitude-web-ui/components/building-blocks/Layout'
import css from '../../latitude-web-ui/components/building-blocks/main.module.css'
import Text from '../../latitude-web-ui/components/building-blocks/Text'
import ThirdPartyAuth from './ThirdPartyAuth'

function Login() {
  const base64regex =
    /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/
  function useParams() {
    return new URLSearchParams(useLocation().search)
  }
  const params = useParams()
  const [emailSelected, selectEmail] = useState(params.get('auth') === 'email')
  const referrer =
    params.get('referrer') &&
    base64regex.test(params.get('referrer')) &&
    params.get('referrer')

  // const LOGIN_PASSWORD_RESET = useVariation('LOGIN_PASSWORD_RESET')
  const LOGIN_PASSWORD_RESET = 'both'
  const showPasswordResetBottom =
    LOGIN_PASSWORD_RESET === 'bottom' || LOGIN_PASSWORD_RESET === 'both'
  const showPasswordError =
    LOGIN_PASSWORD_RESET === 'error' || LOGIN_PASSWORD_RESET === 'both'
  const invalidPassword = params.get('msg') === 'Invalid password'

  useEffect(() => {
    // If there is an error, send them back to the signin page so they can see the error message
    if (invalidPassword) {
      selectEmail(true)
    }
  }, [invalidPassword])

  // eslint-disable-next-line react/no-unstable-nested-components
  function Message(msg) {
    if (!msg) return null
    return (
      <ErrorComponent>
        <div style={{ flexDirection: 'column' }}>
          {msg}
          {msg === 'Invalid password' && showPasswordError && (
            <div className="link">Forgot password?</div>
          )}
        </div>
      </ErrorComponent>
    )
  }

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      selectEmail(true)
    }
  }, [])

  const handleSubmit = (event) => {
    const isSubmitting = event.target.getAttribute('submitting')
    if (isSubmitting) {
      event.preventDefault()
      return false
    }
    event.target.setAttribute('submitting', true)
    setTimeout(() => {
      event.target.removeAttribute('submitting')
    }, 5000)
    return true
  }

  return (
    <form
      onSubmit={handleSubmit}
      action={`${latitudeApiUrl}/user/login`}
      method="post"
      style={{ overflow: 'hidden' }}
    >
      <input
        type="hidden"
        name="returnTo"
        value={decodeURIComponent(
          params.get('returnTo') || `${latitudeAppUrl}`
        )}
      />
      <input type="hidden" name="grantId" value={params.get('grantId') || ''} />
      <Layout
        center
        vertical
        style={{
          height: '100vh',
          backgroundImage: `url('https://latitude-web-images.s3.us-east-2.amazonaws.com/account-bg.jpg')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          overflow: 'hidden',
        }}
      >
        {!emailSelected ? (
          <Layout
            vertical
            gap="var(--small)"
            className={`${css['modal-panel']} ${css.alt}`}
            style={{ textAlign: 'center' }}
          >
            <div style={{ textAlign: 'center' }}>
              <Text
                type="header"
                size="large"
                style={{ marginBottom: 'var(--c2)' }}
              >
                Sign in
              </Text>
              <br />
              {referrer && (
                <Text
                  type="body"
                  size="medium"
                  style={{ marginBottom: 'var(--c2)' }}
                >
                  to continue to {atob(referrer)}
                </Text>
              )}
            </div>

            <ThirdPartyAuth primaryMethod="email" selectEmail={selectEmail} />
            <br />
            <Text style={{ textAlign: 'center' }} type="body" size="small">
              Don&apos;t have an account?{' '}
              <Link to={constructJoinUrl(params)}>
                <div className="link">Join Latitude</div>
              </Link>
            </Text>
          </Layout>
        ) : (
          <Layout
            vertical
            gap="var(--c2)"
            className={`${css['modal-panel']} ${css.alt}`}
          >
            <Text type="header" size="large">
              Sign in with Email
            </Text>
            {referrer && (
              <Text
                type="body"
                size="medium"
                style={{ marginTop: 'var(--c2)' }}
              >
                to continue to {atob(referrer)}
              </Text>
            )}
            <br />
            <TextInput
              name="login"
              transparent
              placeholder="Username or Email"
              required
            />
            <TextInput
              name="password"
              password
              transparent
              placeholder="Password"
              required
            />
            {Message(params.get('msg'))}
            <Button
              accessibilityLabel="Login"
              label="Sign in"
              size="large"
              type="submit"
            />
            <Button
              label="More sign in options..."
              size="large"
              type="blank"
              onPress={() => {
                selectEmail(false)
              }}
            />
            {showPasswordResetBottom && (
              <Text style={{ textAlign: 'center' }} type="body" size="small">
                <Link to={constructForgotPasswordUrl(params)}>
                  <div className="link">Forgot your password?</div>
                </Link>
              </Text>
            )}
          </Layout>
        )}
      </Layout>
    </form>
  )
}

export default Login
