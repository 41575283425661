/* eslint-disable react/prop-types */
import React from 'react'

import styles from './main.module.css'

function Text({ type, size, style, ...props }) {
  return (
    <div
      className={`${styles['arcade-ui-text']} ${styles[type]} ${styles[size]}`}
      style={style}
    >
      {props.children}
    </div>
  )
}

export default Text
