/* eslint-disable react/prop-types */
import React from 'react'

import styles from './main.module.css'

function Panel({
  backgroundImageUrl,
  type,
  span,
  centered,
  hover,
  backgroundColor,
  style,
  noShadow,
  className,
  noBorder,
  ...props
}) {
  return (
    <div
      className={`${styles['arcade-ui-panel']} ${
        styles[centered && 'centered']
      } ${styles[hover && 'hover']} ${styles[type]} ${
        styles[noShadow && 'no-shadow']
      } ${styles[noBorder && 'no-border']} ${
        styles[`arcade-ui-span-${span}`]
      } ${className}`}
      style={{
        backgroundImage: `url(${backgroundImageUrl})`,
        backgroundColor,
        ...style,
      }}
    >
      {props.children}
    </div>
  )
}

export default Panel
