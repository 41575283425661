/* eslint-disable react/prop-types */
import MaterialIcon from 'material-icons-react'
import React from 'react'

import styles from '../main.module.css'
import Text from '../Text'

function Button({
  onPress,
  type,
  label,
  size,
  shape,
  style,
  iconName,
  loading,
}) {
  const newType = !type ? 'primary' : type
  const newSize = !size ? 'medium' : size
  const newShape = !shape ? 'round' : shape
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      onClick={onPress}
      className={`${styles['arcade-ui-button']} ${styles[newType]} ${
        styles[newShape]
      } ${styles[newSize]} ${styles[iconName && !label ? 'icon' : '']}`}
      style={style}
    >
      {iconName && <MaterialIcon size={23} color="inherit" icon={iconName} />}
      {label && !loading && (
        <Text newType="arcade-ui-text-mono" size={`${newSize}`}>
          {label}
        </Text>
      )}
      {loading && 'Loading...'}
    </button>
  )
}

export default Button
