import React, { useState } from 'react'

import Fade from 'react-reveal/Fade'
import { useHistory } from 'react-router-dom'
import useInterval from '../hooks/useInterval'
import Section from '../components/Section/Section'

function ThankYou() {
  const history = useHistory()
  function CountDown() {
    const [count, setCount] = useState(5)
    useInterval(() => {
      setCount(count - 1)
      if (count === 0) {
        history.push('/')
      }
    }, 1000)
    return count
  }
  return (
    <div>
      <Fade bottom distance="24px">
        <Section dark>
          <div className="article">
            <h1>Thank you</h1>
            <p>
              Your submission has been sent. Returning home in {CountDown()}
              ...
            </p>
          </div>
        </Section>
      </Fade>
    </div>
  )
}

export default ThankYou
