import React from 'react'

import { Link } from 'react-router-dom'

import Fade from 'react-reveal/Fade'
import { Get } from 'react-axios'

import Grid from '../latitude-web-ui/components/building-blocks/Grid'
import useWindowDimensions from '../helpers/useWindowDimensions'
import Panel from '../latitude-web-ui/components/building-blocks/Panel'
import css from './Home/home.module.css'
import Section from '../components/Section/Section'

function Blog() {
  const { width } = useWindowDimensions()
  const formatDate = (date) => {
    let d = date.replace(/-/g, '/')
    d = d.replace(/ (.*)/g, '')
    return d.replace(/T(.*)/g, '')
  }
  return (
    <Section dark>
      <h1>Blog</h1>
      <Grid columns={4}>
        <Get url="https://content.latitude.io/ghost/api/v3/content/posts/?key=0b53df7ce5b7702dc3c6c89339&limit=999&include=tags">
          {(error, response, isLoading, makeRequest) => {
            if (error) {
              return (
                <div>
                  Something bad happened: {error.message}
                  <button
                    type="button"
                    onClick={() => makeRequest({ params: { reload: true } })}
                  >
                    Retry
                  </button>
                </div>
              )
            }
            if (isLoading) {
              return <div>Loading...</div>
            }
            if (response !== null) {
              let falseIndex = 0
              return (
                <>
                  {response.data.posts.map((item) => {
                    if (item.featured === false) return null
                    falseIndex += 1
                    return (
                      <div
                        style={{
                          gridColumn:
                            falseIndex === 1
                              ? 'auto / span 2'
                              : width < 700 && 'auto / span 2',
                        }}
                      >
                        <Link to={`/blog/${item.slug}`}>
                          <Fade bottom distance="24px" fraction={0.5}>
                            <Panel
                              className={css['news-card']}
                              style={{
                                borderRadius: 'var(--extraSmall)',
                                backgroundColor: 'var(--dark-1)',
                                padding: 'var(--medium)',
                                marginBottom: 'var(--small)',
                              }}
                            >
                              <p className={css.date}>
                                {falseIndex === 1 && (
                                  <div className={css['news-tag']}>LATEST</div>
                                )}
                                {formatDate(item.published_at)}
                              </p>
                              <h1>{item.title}</h1>
                              <div className={css['p-mask']} />
                              <div
                                className={css['p-cutoff']}
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                  __html: item.html,
                                }}
                              />
                              <Link to={`/blog/${item.slug}`}>
                                <div className="button-like">Read more</div>
                              </Link>
                            </Panel>
                          </Fade>
                        </Link>
                      </div>
                    )
                  })}
                </>
              )
            }
            return <div>Default message before request is made.</div>
          }}
        </Get>
      </Grid>
    </Section>
  )
}

export default Blog
