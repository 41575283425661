/* eslint-disable react/prop-types */
import React from 'react'

import { Get } from 'react-axios'
import Fade from 'react-reveal/Fade'
import MetaTags from 'react-meta-tags'
import Section from '../components/Section/Section'

function News({ articleSlug }) {
  const formatDate = (date) => {
    let d = date.replace(/-/g, '/')
    d = d.replace(/ (.*)/g, '')
    return d.replace(/T(.*)/g, '')
  }
  return (
    <div>
      <Fade bottom distance="24px">
        <Section dark>
          <div className="article">
            <Get url="https://content.latitude.io/ghost/api/v3/content/posts/?key=0b53df7ce5b7702dc3c6c89339&limit=999&include=tags">
              {(error, response, isLoading, makeRequest) => {
                if (error) {
                  return (
                    <div>
                      Something bad happened: {error.message}
                      <button
                        type="button"
                        onClick={() =>
                          makeRequest({ params: { reload: true } })
                        }
                      >
                        Retry
                      </button>
                    </div>
                  )
                }
                if (isLoading) {
                  return <div>Loading...</div>
                }
                if (response !== null) {
                  return (
                    <>
                      {response.data.posts.map((item) => {
                        let imageTags = []
                        let firstImageUrl = ''
                        let paragraphTags = []
                        let firstParagraph = ''
                        if (item.slug === articleSlug) {
                          imageTags = item.html.match(
                            /<img[^>]+src="([^">]+)"/g
                          )
                          paragraphTags = item.html.match(
                            /<\s*p[^>]*>([^<]*)<\s*\/\s*p\s*>/g
                          )
                          if (imageTags) {
                            const imagesArray = imageTags[0].match(/"([^"]*)"/)
                            // eslint-disable-next-line prefer-destructuring
                            firstImageUrl = imagesArray[1]
                          }
                          if (paragraphTags) {
                            const paragraphsArray =
                              paragraphTags[0].match(/>([^"]*)</)
                            // eslint-disable-next-line prefer-destructuring
                            firstParagraph = `${paragraphsArray[1].substring(
                              0,
                              64
                            )}...`
                          }
                        }
                        const featuredImage = () => {
                          if (item.feature_image) return item.feature_image
                          if (firstImageUrl !== '') return firstImageUrl
                          return 'https://s3.us-east-2.amazonaws.com/static.aidungeon.io/artwork/orbwielder.jpg'
                        }

                        return (
                          item.slug === articleSlug && (
                            <div>
                              <MetaTags>
                                <title>{`Latitude Blog: ${item.title}`}</title>
                                <meta name="twitter:card" content="summary" />
                                <meta
                                  name="twitter:site"
                                  content="@aidungeon"
                                />
                                <meta
                                  name="twitter:title"
                                  content={`Latitude Blog: ${item.title}`}
                                />
                                <meta
                                  name="twitter:description"
                                  content={
                                    firstParagraph === ''
                                      ? `${formatDate(item.published_at)}.`
                                      : firstParagraph
                                  }
                                />
                                <meta
                                  name="twitter:image"
                                  content={featuredImage()}
                                />
                                <meta
                                  name="twitter:image:alt"
                                  content="Blog post image"
                                />
                                <meta
                                  name="description"
                                  content={
                                    firstParagraph === ''
                                      ? `${formatDate(item.published_at)}.`
                                      : firstParagraph
                                  }
                                />
                                <meta
                                  property="og:title"
                                  content={`Latitude Blog: ${item.title}`}
                                />
                                <meta
                                  property="og:image"
                                  content={featuredImage()}
                                />
                              </MetaTags>
                              <p className="article-date">
                                {formatDate(item.published_at)}
                              </p>
                              <h1>{item.title}</h1>
                              <div
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{ __html: item.html }}
                              />
                            </div>
                          )
                        )
                      })}
                    </>
                  )
                }
                return <div>Default message before request is made.</div>
              }}
            </Get>
          </div>
        </Section>
      </Fade>
    </div>
  )
}

export default News
