/* eslint-disable jsx-a11y/label-has-associated-control */
import _ from 'lodash'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import Button from '../../latitude-web-ui/components/building-blocks/controls/Button'
import TextInput from '../../latitude-web-ui/components/building-blocks/controls/TextInput'
import Layout from '../../latitude-web-ui/components/building-blocks/Layout'
import css from '../../latitude-web-ui/components/building-blocks/main.module.css'
import Text from '../../latitude-web-ui/components/building-blocks/Text'
import Checkbox from '../../latitude-web-ui/components/building-blocks/controls/Checkbox'
import ErrorComponent from '../../latitude-web-ui/components/building-blocks/controls/Error'
import { latitudeApiUrl } from '../../config/LatitudeApi'
import { constructLoginLinkUrl } from '../../helpers/urlConstructor'
import sendServerEvent from '../../hooks/Events'

function Message(msg) {
  if (!msg) return null
  return <ErrorComponent>{msg}</ErrorComponent>
}

function ThirdPartyLoginOrJoin() {
  const { hash, search } = useLocation()
  if (hash) {
    const hashParams = new URLSearchParams(hash.replace('#', '?'))
    const accessToken = hashParams.get('access_token')
    const state = hashParams.get('state')
    const steamId = hashParams.get('steamid')
    if (accessToken && state && steamId)
      window.location.replace(
        `http://localhost:8000/user/auth/steam?access_token=${accessToken}&state=${state}&steamId=${steamId}`
      )
  }
  function useParams() {
    return new URLSearchParams(search)
  }
  const params = useParams()
  const [email, setEmail] = useState(params.get('email') || '')
  const [username, setUsername] = useState(params.get('username') || '')
  const [emailOptIn, setEmailOptIn] = useState(true)

  const handleSubmit = (event) => {
    const isSubmitting = event.target.getAttribute('submitting')
    if (isSubmitting) {
      event.preventDefault()
      return false
    }
    event.target.setAttribute('submitting', true)
    sendServerEvent({
      eventName: 'link_new_account_submitted',
      variation: JSON.stringify({
        email,
        username,
        emailOptIn,
      }),
    })
    setTimeout(() => {
      event.target.removeAttribute('submitting')
    }, 5000)
    return true
  }

  if (!params.get('grantId')) return null // TODO: Show error and redirect to Voyage

  return (
    <form
      onSubmit={handleSubmit}
      action={`${latitudeApiUrl}/user/auth/join`}
      method="post"
    >
      <input type="hidden" name="grantId" value={params.get('grantId')} />
      {params.get('email') && (
        <>
          <input type="hidden" name="email" value={email} />
          <input type="hidden" name="username" value={username} />
        </>
      )}
      <Layout
        center
        vertical
        style={{
          height: '100vh',
          backgroundImage: `url('https://latitude-web-images.s3.us-east-2.amazonaws.com/account-bg.jpg')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Layout
          vertical
          gap="var(--small)"
          className={`${css['modal-panel']} ${css.alt}`}
          style={{ textAlign: 'center' }}
        >
          <div style={{ textAlign: 'center' }}>
            <Text
              type="header"
              size="large"
              style={{ marginBottom: 'var(--c2)' }}
            >
              Welcome to Voyage
            </Text>
            <Text
              type="body"
              size="medium"
              style={{ marginBottom: 'var(--c2)' }}
            >
              {`Link ${_.capitalize(params.get('service'))} account to Voyage`}
            </Text>
          </div>
          {Message(params.get('msg'))}
          {!params.get('email') && (
            <>
              <TextInput
                name="email"
                transparent
                placeholder="Email"
                value={email}
                onChangeText={(value) => {
                  setEmail(value)
                }}
              />
              <TextInput
                name="username"
                transparent
                placeholder="Username"
                value={username}
                onChangeText={(value) => {
                  setUsername(value)
                }}
              />
              {/* <TextInput
                name="password"
                transparent
                password
                placeholder="Password"
                onChangeText={() => {}}
              /> */}
            </>
          )}

          <Button
            accessibilityLabel="Link to New Account"
            label="Link to New Account"
            size="large"
            type="submit"
          />
          <Button
            accessibilityLabel="Link to Existing Account"
            label="Link to Existing Account"
            size="large"
            type="blank"
            onPress={(e) => {
              e.preventDefault()
              window.location.replace(constructLoginLinkUrl(params))
            }}
          />
          <div
            style={{
              textAlign: 'left',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 8,
            }}
          >
            <Checkbox
              id="optInEmail"
              label="Opt in to email"
              type="checkbox"
              checked={emailOptIn}
              onChange={() => setEmailOptIn(!emailOptIn)}
            />
            <label
              style={{ alignItems: 'left', margin: 'auto', fontSize: 14 }}
              htmlFor="optInEmail"
            >
              Receive Game Updates from Voyage.
            </label>
          </div>
        </Layout>
      </Layout>
    </form>
  )
}

export default ThirdPartyLoginOrJoin
