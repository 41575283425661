import React from 'react'
import './latitude-web-ui/latitude-web-ui-contants.css'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from 'react-router-dom'

import Home from './screens/Home/Home'
import News from './screens/News'
import Contact from './screens/Contact'
import ThankYou from './screens/ThankYou'
import Footer from './components/Footer/Footer'
import Blog from './screens/Blog'
import Login from './screens/Account/Login'
import LoginV2 from './screens/Account/LoginV2'
import LoginLinkAccount from './screens/Account/LoginLinkAccount'
import Join from './screens/Account/Join'
import JoinV2 from './screens/Account/JoinV2'
import NavigationBar from './components/Navigation/NavigationBar'
import ScrollToTop from './components/ScrollToTop'
import Account from './screens/Account/Account'
import PasswordForgot from './screens/Account/PasswordForgot'
import PasswordReset from './screens/Account/PasswordReset'
import PrivacyPolicy from './screens/Legal/PrivacyPolicy'
import TOS from './screens/Legal/TOS'
import JoinInfoV2 from './screens/Account/JoinInfoV2'
import ThirdPartyLoginOrJoin from './screens/Account/ThirdPartyLoginOrJoin'

function NewsArticle() {
  const { slug } = useParams()
  return <News articleSlug={slug} />
}

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <NavigationBar />
        <Switch>
          <Route exact path="/blog">
            <Blog />
          </Route>
          <Route path="/blog/:slug">
            <NewsArticle />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/contact-thank-you">
            <ThankYou />
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="/tos">
            <TOS />
          </Route>
          <Route path="/testers">
            {() => {
              window.location.replace('https://forms.gle/nqu7md7w2PgCqhCo9')
            }}
          </Route>
          <Route path="/account">
            <Account />
            <Route path="/account/login/link" component={LoginLinkAccount} />
            <Route path="/account/login" component={Login} />
            <Route path="/account/v2/login" component={LoginV2} />
            <Route path="/account/join" component={Join} />
            <Route path="/account/v2/join" component={JoinV2} />
            <Route path="/account/forgotPassword" component={PasswordForgot} />
            <Route path="/account/resetPassword" component={PasswordReset} />
            <Route path="/account/join-info" component={JoinInfoV2} />
            <Route path="/account/v2/join-info" component={JoinInfoV2} />
            <Route
              path="/account/login-or-join"
              component={ThirdPartyLoginOrJoin}
            />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </div>
  )
}

export default App
