/* eslint-disable react/prop-types */
import React from 'react'

import css from '../main.module.css'

function TextInput({
  onChangeText,
  onChange,
  textarea,
  placeholder,
  password,
  value,
  transparent,
  name,
  required,
}) {
  const noOp = () => {}
  const onChangeCallback = onChange || onChangeText || noOp
  if (textarea)
    return (
      <textarea
        name={name}
        onChange={(e) => {
          onChangeCallback(e.target.value)
        }}
        placeholder={placeholder}
        value={value}
        className={`${css['text-input-area']}`}
        required={required || false}
      />
    )
  return (
    <input
      name={name}
      type={password ? 'password' : 'text'}
      onChange={(e) => {
        onChangeCallback(e.target.value)
      }}
      placeholder={placeholder}
      value={value}
      className={`${css['text-input']}`}
      style={{ backgroundColor: transparent && 'rgba(24,24,25,0.5)' }}
      required={required || false}
    />
  )
}

export default TextInput
