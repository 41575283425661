/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'

import Fade from 'react-reveal/Fade'
import Section from '../components/Section/Section'
// import { latitudeApiUrl } from '../config/LatitudeApi'

function Contact() {
  return (
    <div>
      <Fade bottom distance="24px">
        <Section dark>
          <div className="article">
            <iframe
              title="Contact Form"
              sandbox="allow-scripts allow-popups allow-forms allow-same-origin"
              width="100%"
              height="660px"
              style={{ border: 0, overflow: 'hidden', overflowX: 'auto' }}
              src="https://forms.helpdesk.com?licenseID=1911077306&contactFormID=ee751ae2-8663-48a1-a42b-595bb60b0c2d"
            >
              Your browser does not allow embedded content.
            </iframe>
            {/* 
            Potential project: use the helpdesk API like we were the Zendesk API, https://api.helpdesk.com/docs#operation/ticketsCreate

            <h1>Contact</h1>
            <p>We welcome your comments, questions, and suggestions.</p>
            <form
              id="contact-form"
              method="post"
              action={`${latitudeApiUrl}/support/website-contact`}
            >
              <label htmlFor="full-name">Name</label>
              <input
                type="text"
                name="name"
                id="full-name"
                placeholder="Name or username"
              />
              <label htmlFor="email-address">Email Address</label>
              <input
                type="email"
                name="email"
                id="email-address"
                placeholder="Your email address"
                required=""
              />
              <label htmlFor="category">Category</label>
              <select name="category" id="category">
                <option value="Other">Select a category...</option>
                <option value="General_question">General question</option>
                <option value="Access">Access/Email verification</option>
                <option value="Bug_report">Bug Report</option>
                <option value="Feature_request">Feature request</option>
                <option value="Feedback">Feedback</option>
                <option value="Payment">Payment</option>
                <option value="Other">Other</option>
              </select>
              <label htmlFor="subject">Subject</label>
              <input type="text" name="subject" id="subject" placeholder="" />
              <label htmlFor="body">Message</label>
              <textarea
                rows="5"
                name="body"
                id="body"
                placeholder="Your message"
                required=""
              />
              <br />
              <br />
              <input
                type="submit"
                value="Send Message"
                className="btn btn-primary"
              />
              <input
                type="text"
                style={{ visibility: 'hidden' }}
                name="bot"
                id="bot"
                value=""
              />
            </form> */}
          </div>
        </Section>
      </Fade>
    </div>
  )
}

export default Contact
