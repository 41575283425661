/* eslint-disable react/prop-types */
import React from 'react'

import css from './section.module.css'

function Section({ label, dark, light, hash, grid, style, children }) {
  return (
    <div
      id={hash || label}
      className={`${css['section-container']} ${dark && css.dark} ${
        light && css.light
      } ${grid && css.grid}`}
      style={{ ...style }}
    >
      {/* disable eslint-disable-next-line jsx-a11y/anchor-has-content */}
      <div className="latitude-web-wrapper">
        <div className={css['section-label']}>{label}</div>
        {children}
      </div>
    </div>
  )
}

export default Section
