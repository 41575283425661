import React from 'react'

import HeroSection from '../../components/HeroSection/HeroSection'
import GamesSection from './GamesSection'
import NewsSection from './NewsSection'

function Home() {
  return (
    <>
      <HeroSection />
      <GamesSection />
      <NewsSection />
    </>
  )
}

export default Home
