const constructQueryStringParams = (params: URLSearchParams): string => {
  const returnTo = params.get('returnTo')
  const grantId = params.get('grantId')
  const queryStringComponents = []
  if (returnTo)
    queryStringComponents.push(`?returnTo=${encodeURIComponent(returnTo)}`)

  if (grantId) {
    queryStringComponents.push(returnTo ? `&` : '?')
    queryStringComponents.push(`grantId=${encodeURIComponent(grantId)}`)
  }

  return queryStringComponents.join('')
}

const constructLoginUrl = (params: URLSearchParams): string => {
  const loginUrlComponents = [
    '/account/login',
    constructQueryStringParams(params),
  ]

  const loginUrl = loginUrlComponents.join('')
  return loginUrl
}

const constructLoginV2Url = (params: URLSearchParams): string => {
  const loginUrlComponents = [
    '/account/v2/login',
    constructQueryStringParams(params),
  ]

  const loginUrl = loginUrlComponents.join('')
  return loginUrl
}

const constructLoginLinkUrl = (params: URLSearchParams): string => {
  const loginUrl = `/account/login/link?grantId=${params.get(
    'grantId'
  )}&service=${params.get('service')}&email=${params.get('email')}`
  return loginUrl
}

const constructJoinUrl = (params: URLSearchParams): string => {
  const joinUrlComponents = [
    '/account/join',
    constructQueryStringParams(params),
  ]

  const joinUrl = joinUrlComponents.join('')
  return joinUrl
}

const constructJoinV2Url = (params: URLSearchParams): string => {
  const joinUrlComponents = [
    '/account/v2/join',
    constructQueryStringParams(params),
  ]

  const joinUrl = joinUrlComponents.join('')
  return joinUrl
}

const constructForgotPasswordUrl = (
  params: URLSearchParams,
  v2 = false
): string => {
  const forgotPasswordUrlComponents = [
    '/account/forgotPassword',
    constructQueryStringParams(params),
  ]

  forgotPasswordUrlComponents.push(`&v2=${v2}`)

  const forgotPasswordUrl = forgotPasswordUrlComponents.join('')
  return forgotPasswordUrl
}

export {
  constructLoginUrl,
  constructLoginV2Url,
  constructLoginLinkUrl,
  constructJoinUrl,
  constructJoinV2Url,
  constructForgotPasswordUrl,
}
