/* eslint-disable react/prop-types */
import React from 'react'

function Layout({
  gap,
  vertical,
  horizontal,
  center,
  centerY,
  centerX,
  style,
  className,
  scroll,
  scrollX,
  scrollY,
  ...props
}) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: vertical ? 'column' : 'row',
        alignItems: center ? 'center' : centerY && 'center',
        justifyContent: center ? 'center' : centerX && 'center',
        gap,
        overflow: scroll ? 'scrollY' : 'hidden',
        overflowY: scrollY && 'scroll',
        overflowX: scrollX && 'scroll',
        ...style,
      }}
      className={className}
    >
      {props.children}
    </div>
  )
}

export default Layout
