/* eslint-disable react/prop-types */
import MaterialIcon from 'material-icons-react'
import React from 'react'

import css from '../main.module.css'

function ErrorComponent({ children }) {
  return (
    <div className={css['error-container']}>
      <div className={css.error}>
        <MaterialIcon size={16} icon="error_outline" color="var(--red)" />
        {children}
      </div>
    </div>
  )
}

export default ErrorComponent
