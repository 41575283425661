import React from 'react'

import Fade from 'react-reveal/Fade'
import Section from '../../components/Section/Section'

function PrivacyPolicy() {
  return (
    <div>
      <Fade bottom distance="24px">
        <Section dark>
          <div className="article">
            <h1>Terms of Service</h1>
            <p>
              Welcome to the Terms of Service (these “Terms”) for the websites
              aidungeon.io, play.aidungeon.io, and latitude.io (the “Websites”),
              and the related mobile applications (the “App”) operated on behalf
              of Oasis Tech, Inc. (doing business as Latitude) (“Company”, “we”
              or “us”), and together with any content, tools, features and
              functionality offered on or through our Website and the App (the
              “Services”).
            </p>
            <p>
              These Terms govern your access to and use of the Services. Please
              read these Terms carefully, as they include important information
              about your legal rights. By accessing and/or using the Services,
              you are agreeing to these Terms. If you do not understand or agree
              to these Terms, please do not use the Services.{' '}
            </p>
            <p>
              For purposes of these Terms, “you” and “your” means you as the
              user of the Services. If you use the Services on behalf of a
              company or other entity then “you” includes you and that entity,
              and you represent and warrant that (a) you are an authorized
              representative of the entity with the authority to bind the entity
              to these Terms, and (b) you agree to these Terms on the
              entity&apos;s behalf.
            </p>
            <p>
              Please note that Section 8 contains an arbitration clause and
              class action waiver. By agreeing to these Terms, you agree (a) to
              resolve all disputes with us through binding individual
              arbitration, which means that you waive any right to have those
              disputes decided by a judge or jury, and (b) that you waive your
              right to participate in class actions, class arbitrations, or
              representative actions. You have the right to opt-out of
              arbitration as explained in Section 8.
            </p>
            <br />
            <h2>Who May Use the Services</h2>
            <p>
              You must be 18 years of age or older to use the Services. By using
              the Services, you represent and warrant that you meet these
              requirements.
            </p>
            <br />
            <h2>User Accounts, SUBSCRIPTIONS and free trials</h2>
            <h3>Creating and Safeguarding your Account.</h3>
            <p>
              To use certain of the Services, you may need to create an account
              (“Account”). You agree to provide us with accurate, complete and
              updated information for your Account. You are solely responsible
              for any activity on your Account and for maintaining the
              confidentiality and security of your password. We are not liable
              for any acts or omissions by you in connection with your Account.
              You must immediately notify us at contact-us@latitude.io if you
              know or have any reason to suspect that your Account or password
              have been stolen, misappropriated or otherwise compromised, or in
              case of any actual or suspected unauthorized use of your Account.
            </p>
            <h3>Paid services.</h3>
            <p>
              If you buy or subscribe to any of our paid Services, you agree to
              pay us the applicable fees and taxes in U.S. Dollars. Failure to
              pay these fees and taxes will result in the termination of your
              access to the paid Services. You agree that (a) we may store and
              continue billing your payment method (e.g. credit card) to avoid
              interruption of the Services, and (b) we may calculate taxes
              payable by you based on the billing information that you provide
              us at the time of purchase. We reserve the right to change our
              subscription plans or adjust pricing for the Services in any
              manner and at any time as we may determine in our sole and
              absolute discretion. Except as otherwise provided in these Terms,
              any price changes or changes to your subscription plan will take
              effect following reasonable notice to you. All subscriptions are
              payable in accordance with payment terms in effect at the time the
              subscription becomes payable. Payment can be made by credit card,
              debit card, or other means that we may make available.
              Subscriptions will not be processed until payment has been
              received in full, and any holds on your account by any other
              payment processor are solely your responsibility.
            </p>
            <h3>Subscription Renewals and Cancellations.</h3>
            <p>
              You agree that if you purchase a subscription to use a paid
              portion of the Services, your subscription will automatically
              renew at the subscription period frequency referenced on your
              subscription page (or if not designated, then monthly), and your
              payment method will automatically be charged at the start of each
              new subscription period for the fees and taxes applicable to that
              period. To avoid future subscription charges, you must cancel your
              subscription, in which case your cancellation will be effective at
              the end of your then-current subscription period. You can cancel
              your subscription through the subscription settings on your
              account page on the Services.
            </p>
            <h3>No Refunds.</h3>
            <p>
              Except as expressly set forth in these Terms, payments for any
              Services are non-refundable and there are no credits for partially
              used periods. Following any cancellation by you, however, you will
              continue to have access to the paid Services through the end of
              the subscription period for which payment has already been made.
            </p>
            <h3>Promotional Codes.</h3>
            <p>
              We may offer certain promotional codes, coupon codes or similar
              offers (“Promotional Codes”) that may be redeemed for free
              subscriptions or other features or benefits related to the
              Services, subject to any additional terms that the Company
              establishes. You agree that Promotional Codes: (a) may not be
              duplicated, sold or transferred in any manner, or made available
              by you to the general public (whether posted to a public forum,
              coupon collecting service, or otherwise), unless expressly
              permitted by the Company; (b) may be disabled or have additional
              conditions applied to them by the Company at any time for any
              reason without liability to the Company; (c) are not valid for
              cash or other credits or points; and (d) may expire prior to your
              use.
            </p>
            <br />
            <h2>ORDERS FOR PRODUCTS AND/OR SERVICES</h2>
            <h3>Payment.</h3>
            <p>
              The Services may permit you to purchase certain physical or
              digital items through the Services, including products or services
              of third parties that are offered through the Services
              (“Offerings”). You acknowledge and agree that all information you
              provide with regards to a purchase of Offerings, including,
              without limitation, credit card, Stripe, or other payment
              information, is accurate, current and complete. You represent and
              warrant that you have the legal right to use the payment method
              you provide to us or our payment processor, including, without
              limitation, any credit card you provide when completing a
              transaction. We reserve the right, with or without prior notice
              and in our sole and complete discretion, to (a) discontinue,
              modify, or limit the available quantity of, any Offerings, and (b)
              refuse to allow any user to purchase any Offering or deliver such
              Offerings to a user or a user designated address. When you
              purchase Offerings, you (a) agree to pay the price for such
              Offerings as set forth in the applicable Service, and (b)
              authorize us (or our payment processor such as Stripes) to charge
              your credit card or other payment method for the Full Purchase
              Amount. Unless otherwise noted, all currency references are in
              U.S. Dollars. All fees and charges are payable in accordance with
              payment terms in effect at the time the fee or the charge becomes
              payable. Payment can be made by credit card, debit card, or
              through Stripe or other means that we may make available. Orders
              will not be processed until payment has been received in full, and
              any holds on your account by Stripe or any other payment processor
              are solely your responsibility.
            </p>
            <h3>Changes & Pricing.</h3>
            <p>
              The Company may, at any time, revise or change the pricing,
              availability, specifications, content, descriptions or features of
              any Offerings. While we attempt to be as accurate as we can in our
              descriptions for the Offerings, we do not warrant that Offering
              descriptions are accurate, complete, reliable, current, or
              error-free. The inclusion of any Offerings for purchase through
              the Services at a particular time does not imply or warrant that
              the Offerings will be available at any other time. We reserve the
              right to change prices for Offerings displayed on the Services at
              any time, and to correct pricing errors that may inadvertently
              occur (and to cancel any orders in our sole discretion that were
              purchased with pricing errors). All such changes shall be
              effective immediately upon posting of such new Offering prices to
              the Services and/or upon making the customer aware of the pricing
              error.
            </p>
            <h3>Order Confirmation.</h3>
            <p>
              Once we receive your order for an Offering, we will provide you
              with an order confirmation. Your receipt of an order confirmation,
              however, does not signify our acceptance of your order, nor does
              it constitute confirmation of our offer to sell; we are simply
              confirming that we received your order. We reserve the right at
              any time after receiving your order to accept or decline your
              order for any reason and at our sole discretion. If we cancel an
              order after you have already been billed, then we will refund the
              billed amount.
            </p>
            <br />
            <h2>Location of Our Privacy Policy</h2>
            <p>
              Privacy Policy. Our Privacy Policy describes how we handle the
              information you provide to us when you use the Services. For an
              explanation of our privacy practices, please visit our Privacy
              Policy located at latitude./privacy-policy
            </p>
            <br />
            <h2>Rights We Grant You</h2>
            <p>
              Our intent in this and the following section has three parts:
              <br />
              <br />
              Ensure we can operate the Services (which means we need a standard
              user-generated content copyright for all content produced on the
              site to protect us and you legally)
              <br />
              <br />
              Let users use the content they&apos;ve created (“Your Content”)
              pretty much however they want.
              <br />
              <br />
              Don&apos;t allow users to abuse content created by other users
              (“Not Your Content”).
              <br />
              <br />
              And here&apos;s the nitty gritty.
              <br />
              <br />
              <h3>License Grant.</h3>
              <p>
                Subject to your compliance with these Terms, the Company hereby
                grants to you, a personal, worldwide, royalty-free,
                non-assignable, non-sublicensable, non-transferrable, and
                non-exclusive license to use the software provided to you as
                part of the Services (and to download a single copy of the App
                onto the equipment or device specified by us). This license has
                the sole purpose of enabling you to use and enjoy the benefit of
                the Services as provided by us, in the manner permitted by these
                Terms and subject to the use restrictions described below. Your
                access and use of the Services may be interrupted from time to
                time for any of several reasons, including, without limitation,
                the malfunction of equipment, periodic updating, maintenance or
                repair of the Service or other actions that Company, in its sole
                discretion, may elect to take.
              </p>
              <h3>Restrictions On Your Use of the Services.</h3>
              <p>
                You may not do any of the following, unless applicable laws or
                regulations prohibit these restrictions or you have our written
                permission to do so: download, modify, copy, distribute,
                transmit, display, perform, reproduce, duplicate, publish,
                license, create derivative works from, or offer for sale any
                information contained on, or obtained from or through, the
                Services; duplicate, decompile, reverse engineer, disassemble or
                decode the Services (including any underlying idea or
                algorithm), or attempt to do any of the same; use, reproduce or
                remove any copyright, trademark, service mark, trade name,
                slogan, logo, image, or other proprietary notation displayed on
                or through the Services; use cheats, automation software (bots),
                hacks, modifications (mods) or any other unauthorized
                third-party software designed to modify the Services; exploit
                the Services for any commercial purpose, including without
                limitation communicating or facilitating any commercial
                advertisement or solicitation; access or use the Services in any
                manner that could disable, overburden, damage, disrupt or impair
                the Services or interfere with any other party’s access to or
                use of the Services or use any device, software or routine that
                causes the same; attempt to gain unauthorized access to,
                interfere with, damage or disrupt the Services, accounts
                registered to other users, or the computer systems or networks
                connected to the Services; circumvent, remove, alter,
                deactivate, degrade or thwart any technological measure or
                content protections of the Services; use any robot, spider,
                crawlers or other automatic device, process, software or queries
                that intercepts, “mines,” scrapes or otherwise accesses the
                Services to monitor, extract, copy or collect information or
                data from or through the Services, or engage in any manual
                process to do the same; introduce any viruses, trojan horses,
                worms, logic bombs or other materials that are malicious or
                technologically harmful into our systems; use the Services for
                illegal, harassing, unethical, or disruptive purposes; violate
                any applicable law or regulation in connection with your access
                to or use of the Services; or access or use the Services in any
                way not expressly permitted by these Terms.
              </p>
              <h3>Use of the App.</h3>
              <p>
                You are responsible for providing the mobile device, wireless
                service plan, software, Internet connections and/or other
                equipment or services that you need to download, install and use
                the App. We do not guarantee that the App can be accessed and
                used on any particular device or with any particular service
                plan. We do not guarantee that the App or will be available in,
                or that orders for Products can be placed from, any particular
                geographic location. As part of the Services and to update you
                regarding the status of deliveries, you may receive push
                notifications, local client notifications, text messages,
                picture messages, alerts, emails or other types of messages
                directly sent to you in connection with the App (“Push
                Messages”). You acknowledge that, when you use the App, your
                wireless service provider may charge you fees for data, text
                messaging and/or other wireless access, including in connection
                with Push Messages. You have control over the Push Messages
                settings, and can opt in or out of these Push Messages through
                the Services or through your mobile device’s operating system
                (with the possible exception of infrequent, important service
                announcements and administrative messages). Please check with
                your wireless service provider to determine what fees apply to
                your access to and use of the App, including your receipt of
                Push Messages from the Company. You are solely responsible for
                any fee, cost or expense that you incur to download, install
                and/or use the App on your mobile device, including for your
                receipt of push messages from the Company.
              </p>
              <h3>Mobile Software from the Apple App Store.</h3>
              <p>
                The following terms and conditions apply to you only if you are
                using the App from the Apple App Store. To the extent the other
                terms and conditions of these Terms are less restrictive than,
                or otherwise conflict with, the terms and conditions of this
                paragraph, the more restrictive or conflicting terms and
                conditions in this paragraph apply, but solely with respect to
                the App from the Apple App Store. You acknowledge and agree that
                these Terms are solely between you and the Company, not Apple,
                and that Apple has no responsibility for the App or content
                thereof. Your use of the App must comply with the App Store’s
                applicable terms of use. You acknowledge that Apple has no
                obligation whatsoever to furnish any maintenance and support
                services with respect to the App. In the event of any failure of
                the App to conform to any applicable warranty, you may notify
                Apple, and Apple will refund the purchase price, if any, for the
                App to you. To the maximum extent permitted by applicable law,
                Apple will have no other warranty obligation whatsoever with
                respect to the App, and any other claims, losses, liabilities,
                damages, costs or expenses attributable to any failure to
                conform to any warranty will be solely governed by these Terms.
                You and the Company acknowledge that Apple is not responsible
                for addressing any claims of yours or any third party relating
                to the App or your possession and/or use of the App, including,
                but not limited to: (a) product liability claims, (b) any claim
                that the App fails to conform to any applicable legal or
                regulatory requirement, and (c) claims arising under consumer
                protection or similar legislation. You and the Company
                acknowledge that, in the event of any third party claim that the
                App or your possession and use of that App infringes that third
                party’s intellectual property rights, the Company, not Apple,
                will be solely responsible for the investigation, defense,
                settlement and discharge of any such intellectual property
                infringement claim to the extent required by these Terms. You
                must comply with applicable third party terms of agreement when
                using the App. You and the Company acknowledge and agree that
                Apple, and Apple’s subsidiaries, are third party beneficiaries
                of these Terms as they relate to your use of the App, and that,
                upon your acceptance of these Terms, Apple will have the right
                (and will be deemed to have accepted the right) to enforce these
                Terms against you as a third party beneficiary thereof.
              </p>
              <br />
              <h2>Ownership and Content</h2>
              <h3>Ownership of the Services.</h3>
              <p>
                The Services, including their “look and feel” (e.g., text,
                graphics, images, logos), proprietary content, information and
                other materials, are protected under copyright, trademark and
                other intellectual property laws. You agree that the Company
                and/or its licensors own all right, title and interest in and to
                the Services (including any and all intellectual property rights
                therein) and you agree not to take any action(s) inconsistent
                with such ownership interests. We and our licensors reserve all
                rights in connection with the Services and its content (other
                than Your Content), including, without limitation, the exclusive
                right to create derivative works.
              </p>
              <h3>Ownership of Trademarks.</h3>
              <p>
                The Company’s name and logo, the AI Dungeon name and logo, and
                all related names, logos, product and service names, designs and
                slogans are trademarks of the Company or its affiliates or
                licensors. Other names, logos, product and service names,
                designs and slogans that appear on the Services are the property
                of their respective owners, who may or may not be affiliated
                with, connected to, or sponsored by us.
              </p>
              <h3>Ownership of Feedback.</h3>
              <p>
                We welcome feedback, comments and suggestions for improvements
                to the Services (“Feedback”). You acknowledge and expressly
                agree that any contribution of Feedback does not and will not
                give or grant you any right, title or interest in the Services
                or in any such Feedback. All Feedback becomes the sole and
                exclusive property of the Company, and the Company may use and
                disclose Feedback in any manner and for any purpose whatsoever
                without further notice or compensation to you and without
                retention by you of any proprietary or other right or claim. You
                hereby assign to the Company any and all right, title and
                interest (including, but not limited to, any patent, copyright,
                trade secret, trademark, show-how, know-how, moral rights and
                any and all other intellectual property right) that you may have
                in and to any and all Feedback.
              </p>
              <h3>Your Content License Grant.</h3>
              <p>
                In connection with your use of the Services, you may be able to
                post, upload, or submit content to be made available through the
                Services (“Your Content”). In order to operate the Service, we
                must obtain from you certain license rights in Your Content so
                that actions we take in operating the Service are not considered
                legal violations. Accordingly, by using the Service and
                uploading Your Content, you grant us a license to access, use,
                host, cache, store, reproduce, transmit, display, publish,
                distribute, and modify (for technical purposes, e.g., making
                sure content is viewable on smartphones as well as computers and
                other devices) Your Content but solely as required to be able to
                operate and provide the Services. You agree that these rights
                and licenses are royalty free, transferable, sub-licensable,
                worldwide and irrevocable (for so long as Your Content is stored
                with us), and include a right for us to make Your Content
                available to, and pass these rights along to, others with whom
                we have contractual relationships related to the provision of
                the Services, solely for the purpose of providing such Services,
                and to otherwise permit access to or disclose Your Content to
                third parties if we determine such access is necessary to comply
                with our legal obligations. As part of the foregoing license
                grant you agree that the other users of the Services shall have
                the right to comment on and/or tag Your Content and/or to use,
                display, modify, or include a copy of Your Content as part of
                their own use of the Services; except that the foregoing shall
                not apply to any of Your Content that you post privately for
                non-public display on the Services. You also agree that other
                users of the Service shall have the right to publish Adventures
                derived from your published Scenarios. When publishing, you also
                agree to abide by the Community Guidelines, which is located at
                aidungeon.io/community-guidelines. By posting or submitting Your
                Content through the Services, you represent and warrant that you
                have, or have obtained, all rights, licenses, consents,
                permissions, power and/or authority necessary to grant the
                rights granted herein for Your Content. You agree that Your
                Content will not contain material subject to copyright or other
                proprietary rights, unless you have the necessary permission or
                are otherwise legally entitled to post the material and to grant
                us the license described above.
              </p>
              <h3>Notice of Infringement – DMCA Policy</h3>
              <p>
                If you believe that any text, graphics, photos, audio, videos or
                other materials or works uploaded, downloaded or appearing on
                the Services have been copied in a way that constitutes
                copyright infringement, you may submit a notification to our
                copyright agent in accordance with 17 USC 512(c) of the Digital
                Millennium Copyright Act (the “DMCA”), by providing the
                following information in writing: identification of the
                copyrighted work that is claimed to be infringed; identification
                of the allegedly infringing material that is requested to be
                removed, including a description of where it is located on the
                Service; information for our copyright agent to contact you,
                such as an address, telephone number and e-mail address; a
                statement that you have a good faith belief that the identified,
                allegedly infringing use is not authorized by the copyright
                owners, its agent or the law; a statement that the information
                above is accurate, and under penalty of perjury, that you are
                the copyright owner or the authorized person to act on behalf of
                the copyright owner; and the physical or electronic signature of
                a person authorized to act on behalf of the owner of the
                copyright or of an exclusive right that is allegedly infringed.
              </p>
              <p>
                Notices of copyright infringement claims should be sent by mail
                to: Latitude, Attn: COPYRIGHT AGENT, 8 The Green, Suite B,
                Dover, DE 19901; or by e-mail to contact-us@latitude.io. It is
                our policy, in appropriate circumstances and at our discretion,
                to disable or terminate the accounts of users who repeatedly
                infringe copyrights or intellectual property rights of others.
              </p>
              <br />
              <h2>Disclaimers, Limitations of Liability and Indemnification</h2>
              <h3>Disclaimers.</h3>
              <p>
                YOUR ACCESS TO AND USE OF THE SERVICES ARE AT YOUR OWN RISK. YOU
                UNDERSTAND AND AGREE THAT THE SERVICES ARE PROVIDED TO YOU ON AN
                “AS IS” AND “AS AVAILABLE” BASIS. WITHOUT LIMITING THE
                FOREGOING, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW,
                THE COMPANY, ITS PARENTS, AFFILIATES, RELATED COMPANIES,
                OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, REPRESENTATIVES,
                PARTNERS AND LICENSORS (THE “THE COMPANY ENTITIES”) DISCLAIM ALL
                WARRANTIES AND CONDITIONS, WHETHER EXPRESS OR IMPLIED, OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR
                NON-INFRINGEMENT. The Company Entities make no warranty or
                representation and disclaim all responsibility and liability
                for: (a) the completeness, accuracy, availability, timeliness,
                security or reliability of the Services; (b) any harm to your
                computer system, loss of data, or other harm that results from
                your access to or use of the Services; (c) the operation or
                compatibility with any other application or any particular
                system or device; (d) whether the Services will meet your
                requirements or be available on an uninterrupted, secure or
                error-free basis; and (e) the deletion of, or the failure to
                store or transmit, Your Content and other communications
                maintained by the Services. No advice or information, whether
                oral or written, obtained from the Company Entities or through
                the Services, will create any warranty or representation not
                expressly made herein.
              </p>
              <h3>Limitations of Liability.</h3>
              <p>
                TO THE EXTENT NOT PROHIBITED BY LAW, YOU AGREE THAT IN NO EVENT
                WILL THE COMPANY ENTITIES BE LIABLE (A) FOR DAMAGES OF ANY KIND,
                INCLUDING DIRECT, INDIRECT, SPECIAL, EXEMPLARY, INCIDENTAL,
                CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING, BUT NOT LIMITED
                TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, LOSS OF USE,
                DATA OR PROFITS, BUSINESS INTERRUPTION OR ANY OTHER DAMAGES OR
                LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OR INABILITY TO
                USE THE SERVICES), HOWEVER CAUSED AND UNDER ANY THEORY OF
                LIABILITY, WHETHER UNDER THESE TERMS OR OTHERWISE ARISING IN ANY
                WAY IN CONNECTION WITH THE SERVICES OR THESE TERMS AND WHETHER
                IN CONTRACT, STRICT LIABILITY OR TORT (INCLUDING NEGLIGENCE OR
                OTHERWISE) EVEN IF THE COMPANY ENTITIES HAVE BEEN ADVISED OF THE
                POSSIBILITY OF SUCH DAMAGE, OR (B) FOR ANY OTHER CLAIM, DEMAND
                OR DAMAGES WHATSOEVER RESULTING FROM OR ARISING OUT OF OR IN
                CONNECTION WITH THESE TERMS OR THE DELIVERY, USE OR PERFORMANCE
                OF THE SERVICES. SOME JURISDICTIONS (SUCH AS THE STATE OF NEW
                JERSEY) DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL
                OR CONSEQUENTIAL DAMAGES, SO THE ABOVE EXCLUSION OR LIMITATION
                MAY NOT APPLY TO YOU. THE COMPANY ENTITIES’ TOTAL LIABILITY TO
                YOU FOR ANY DAMAGES FINALLY AWARDED SHALL NOT EXCEED THE AMOUNT
                OF ONE HUNDRED DOLLARS ($100.00), OR THE AMOUNT YOU PAID THE
                COMPANY ENTITIES, IF ANY, IN THE PAST SIX (6) MONTHS FOR THE
                SERVICES (OR OFFERINGS PURCHASED ON THE SERVICES) GIVING RISE TO
                THE CLAIM. THE FOREGOING LIMITATIONS WILL APPLY EVEN IF THE
                ABOVE STATED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
              </p>
              <h3>Indemnification.</h3>
              <p>
                By entering into these Terms and accessing or using the
                Services, you agree that you shall defend, indemnify and hold
                the Company Entities harmless from and against any and all
                claims, costs, damages, losses, liabilities and expenses
                (including attorneys’ fees and costs) incurred by the Company
                Entities arising out of or in connection with: (a) your
                violation or breach of any term of these Terms or any applicable
                law or regulation; (b) your violation of any rights of any third
                party; (c) your access to or use of the Services; (d) Your
                Content, or (e) your negligence or wilful misconduct.
              </p>
              <br />
              <h2>ARBITRATION AND CLASS ACTION WAIVER</h2>
              <h3>Informal Process First.</h3>
              <p>
                You agree that in the event of any dispute between you and the
                Company Entities, you will first contact the Company and make a
                good faith sustained effort to resolve the dispute before
                resorting to more formal means of resolution, including without
                limitation, any court action.
              </p>
              <h3>Arbitration Agreement and Class Action Waiver.</h3>
              <p>
                After the informal dispute resolution process, any remaining
                dispute, controversy, or claim (collectively, “Claim”) relating
                in any way to your use of the Company’s services and/or
                products, including the Services, will be resolved by
                arbitration, including threshold questions of arbitrability of
                the Claim. You and the Company agree that any Claim will be
                settled by final and binding arbitration, using the English
                language, administered by JAMS under its Comprehensive
                Arbitration Rules and Procedures (the “JAMS Rules”) then in
                effect (those rules are deemed to be incorporated by reference
                into this section, and as of the date of these Terms).
                Arbitration will be handled by a sole arbitrator in accordance
                with the JAMS Rules. Judgment on the arbitration award may be
                entered in any court that has jurisdiction. Any arbitration
                under these Terms will take place on an individual basis – class
                arbitrations and class actions are not permitted. You understand
                that by agreeing to these Terms, you and the Company are each
                waiving the right to trial by jury or to participate in a class
                action or class arbitration. Notwithstanding the foregoing, you
                and the Company will have the right to bring an action in a
                court of proper jurisdiction for injunctive or other equitable
                or conservatory relief, pending a final decision by the
                arbitrator. You may instead assert your claim in “mediumSmall
                claims” court, but only if your claim qualifies, your claim
                remains in such court and your claim remains on an individual,
                non-representative and non-class basis.
              </p>
              <h3>Costs of Arbitration.</h3>
              <p>
                Payment for any and all reasonable JAMS filing, administrative
                and arbitrator fees will be in accordance with the JAMS Rules.
                If the value of your claim does not exceed $10,000, the Company
                will pay for the reasonable filing, administrative and
                arbitrator fees associated with the arbitration, unless the
                arbitrator finds that either the substance of your claim or the
                relief sought was frivolous or brought for an improper purpose.
              </p>
              <h3>Opt-Out.</h3>
              <p>
                You have the right to opt-out and not be bound by the
                arbitration provisions set forth in these Terms by sending
                written notice of your decision to opt-out to
                contact-us@latitude.io or to the U.S. mailing address listed in
                the “How to Contact Us” section of these Terms. The notice must
                be sent to the Company within thirty (30) days of your
                registering to use the Services or agreeing to these Terms,
                otherwise you shall be bound to arbitrate disputes in accordance
                with these Terms. If you opt-out of these arbitration
                provisions, the Company also will not be bound by them.
              </p>
              <br />
              <h2>Additional Provisions</h2>
              <h3>Updating These Terms.</h3>
              <p>
                We may modify these Terms from time to time in which case we
                will update the “Last Revised” date at the top of these Terms.
                If we make changes that are material, we will use reasonable
                efforts to attempt to notify you, such as by e-mail and/or by
                placing a prominent notice on the first page of the Website.
                However, it is your sole responsibility to review these Terms
                from time to time to view any such changes. The updated Terms
                will be effective as of the time of posting, or such later date
                as may be specified in the updated Terms. Your continued access
                or use of the Services after the modifications have become
                effective will be deemed your acceptance of the modified Terms.
              </p>
              <h3>Termination of License and Your Account.</h3>
              <p>
                If you breach any of the provisions of these Terms, all licenses
                granted by the Company will terminate automatically.
                Additionally, the Company may suspend, disable, or delete your
                Account and/or the Services (or any part of the foregoing) with
                or without notice, for any or no reason. If the Company deletes
                your Account for any suspected breach of these Terms by you, you
                are prohibited from re-registering for the Services under a
                different name. In the event of Account deletion for any reason,
                the Company may, but is not obligated to, delete any of Your
                Content. the Company shall not be responsible for the failure to
                delete or deletion of Your Content. All sections which by their
                nature should survive the termination of these Terms shall
                continue in full force and effect subsequent to and
                notwithstanding any termination of this Agreement by the Company
                or you. Termination will not limit any of the Company’s other
                rights or remedies at law or in equity.
              </p>
              <h3>Injunctive Relief.</h3>
              <p>
                You agree that a breach of these Terms will cause irreparable
                injury to the Company for which monetary damages would not be an
                adequate remedy and the Company shall be entitled to equitable
                relief in addition to any remedies it may have hereunder or at
                law without a bond, other security or proof of damages.
              </p>
              <h3>California Residents.</h3>
              <p>
                If you are a California resident, in accordance with Cal. Civ.
                Code § 1789.3, you may report complaints to the Complaint
                Assistance Unit of the Division of Consumer Services of the
                California Department of Consumer Affairs by contacting them in
                writing at 1625 North Market Blvd., Suite N 112 Sacramento, CA
                95834, or by telephone at (800) 952-5210.
              </p>
              <h3>Export Laws.</h3>
              <p>
                You agree that you will not export or re-export, directly or
                indirectly, the Services and/or other information or materials
                provided by the Company hereunder, to any country for which the
                United States or any other relevant jurisdiction requires any
                export license or other governmental approval at the time of
                export without first obtaining such license or approval. In
                particular, but without limitation, the Services may not be
                exported or re-exported (a) into any U.S. embargoed countries or
                any country that has been designated by the U.S. Government as a
                “terrorist supporting” country, or (b) to anyone listed on any
                U.S. Government list of prohibited or restricted parties,
                including the U.S. Treasury Department’s list of Specially
                Designated Nationals or the U.S. Department of Commerce Denied
                Person’s List or Entity List. By using the Services, you
                represent and warrant that you are not located in any such
                country or on any such list. You are responsible for and hereby
                agree to comply at your sole expense with all applicable United
                States export laws and regulations.
              </p>
              <h3>Miscellaneous.</h3>
              <p>
                If any provision of these Terms shall be unlawful, void or for
                any reason unenforceable, then that provision shall be deemed
                severable from these Terms and shall not affect the validity and
                enforceability of any remaining provisions. These Terms and the
                licenses granted hereunder may be assigned by the Company but
                may not be assigned by you without the prior express written
                consent of the Company. No waiver by either party of any breach
                or default hereunder shall be deemed to be a waiver of any
                preceding or subsequent breach or default. The section headings
                used herein are for reference only and shall not be read to have
                any legal effect. The Services are operated by us in the United
                States. Those who choose to access the Services from locations
                outside the United States do so at their own initiative and are
                responsible for compliance with applicable local laws. These
                Terms are governed by the laws of the State of Delaware, without
                regard to conflict of laws rules.
              </p>
              <h3>How to Contact Us.</h3>
              <p>
                You may contact us regarding the Services or these Terms at: 8
                The Green, Suite B, Dover, DE 19901, by phone at (801) 203-0065
                or by e-mail at contact-us@latitude.io.
              </p>
            </p>
            <br />
          </div>
        </Section>
      </Fade>
    </div>
  )
}

export default PrivacyPolicy
