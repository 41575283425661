/* eslint-disable react/prop-types */
import React from 'react'
import { useLocation } from 'react-router'
import Button from '../../latitude-web-ui/components/building-blocks/controls/Button'
import Layout from '../../latitude-web-ui/components/building-blocks/Layout'
import AuthPip from './AuthPip'
import Apple from './apple.png'
import Discord from './discord.png'
import Google from './google.png'
import Steam from './steam.png'
import Twitch from './twitch.png'
import { latitudeApiUrl } from '../../config/LatitudeApi'
import sendServerEvent from '../../hooks/Events'

function ThirdPartyAuthV2({ selectEmail, primaryMethod }) {
  // console.log('ThirdPartyAuthV2 => ', `${latitudeApiUrl}/user/auth/v2/apple`)
  const params = new URLSearchParams(useLocation().search)
  // eslint-disable-next-line react/no-unstable-nested-components
  function Or() {
    return (
      <Layout centerY>
        <hr style={{ flex: 1, maxHeight: 1, borderColor: 'var(--dark-3)' }} />
        <p style={{ margin: 'var(--small)' }}> or </p>
        <hr style={{ flex: 1, maxHeight: 1, borderColor: 'var(--dark-3)' }} />
      </Layout>
    )
  }
  const loginMethodMap = {
    email: {
      onClick: () => {
        selectEmail(true)
      },
      icon: null,
    },
    discord: {
      onClick: () => {
        sendServerEvent({
          eventName: 'discord_login_initiated',
          variation: params.get('grantId'),
        })
        window.location = `https://discord.com/api/oauth2/authorize?client_id=915307003381964880&state=${encodeURIComponent(
          params.get('grantId')
        )}&response_type=code&redirect_uri=${encodeURIComponent(
          latitudeApiUrl
        )}/user/auth/v2/discord&scope=identify%20email`
      },
      icon: Discord,
      show: true,
    },
    google: {
      onClick: () => {
        sendServerEvent({
          eventName: 'google_login_initiated',
          variation: params.get('grantId'),
        })
        window.location = `https://accounts.google.com/o/oauth2/v2/auth?client_id=886879764674-fhjvslb1i91a0mbu98obicn03qjloond.apps.googleusercontent.com&state=${encodeURIComponent(
          params.get('grantId')
        )}&response_type=code&redirect_uri=${encodeURIComponent(
          latitudeApiUrl
        )}/user/auth/v2/google&scope=email`
      },
      icon: Google,
      show: true,
    },
    twitch: {
      onClick: () => {
        sendServerEvent({
          eventName: 'twitch_login_initiated',
          variation: params.get('grantId'),
        })
        window.location = `https://id.twitch.tv/oauth2/authorize?client_id=nkry0zl0lbcd0lj6k670ku83cxp7nl&state=${encodeURIComponent(
          params.get('grantId')
        )}&redirect_uri=${encodeURIComponent(
          latitudeApiUrl
        )}/user/auth/v2/twitch&response_type=code&scope=${encodeURIComponent(
          'user:read:email'
        )}`
      },
      icon: Twitch,
      show: true,
    },
    apple: {
      onClick: () => {
        sendServerEvent({
          eventName: 'apple_login_initiated',
          variation: params.get('grantId'),
        })
        window.location = `https://appleid.apple.com/auth/authorize?client_id=io.latitude.api&redirect_uri=${encodeURIComponent(
          `${latitudeApiUrl}/user/auth/v2/apple`
        )}&response_type=code&response_mode=form_post&scope=email&state=${encodeURIComponent(
          params.get('grantId')
        )}`
      },
      icon: Apple,
      show: true,
    },
    // Steam OAuth. In order to change the redirect uri, you must contact Steam support.
    steam: {
      onClick: () => {
        sendServerEvent({
          eventName: 'steam_login_initiated',
          variation: params.get('grantId'),
        })
        window.location = `https://steamcommunity.com/oauth/login?response_type=token&client_id=3BEEE99B&mobileminimal=1&state=${encodeURIComponent(
          params.get('grantId')
        )}`
      },
      icon: Steam,
      show: false,
    },
  }
  return (
    <>
      <Button
        type="secondary"
        size="large"
        label={
          primaryMethod === 'email' ? (
            'email'
          ) : (
            <Layout gap="var(--extraSmall)">
              <img
                src={loginMethodMap[primaryMethod].icon}
                style={{ height: 24, width: 24 }}
                alt={primaryMethod}
              />
              {primaryMethod}
            </Layout>
          )
        }
        iconName={primaryMethod === 'email' && 'email'}
        onPress={() => {
          if (primaryMethod === 'email') selectEmail(true)
        }}
      />
      <Or />
      <Layout gap="var(--small)" style={{ flexWrap: 'wrap' }}>
        {Object.keys(loginMethodMap).map((key) => {
          if (key === primaryMethod || !loginMethodMap[key].show) return null
          return <AuthPip type={key} onClick={loginMethodMap[key].onClick} />
        })}
      </Layout>
    </>
  )
}

export default ThirdPartyAuthV2
