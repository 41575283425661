import { latitudeApiUrl } from '../config/LatitudeApi'

const sendServerEvent = ({
  eventName,
  variation,
}: {
  eventName: string
  variation: string
}): void => {
  const analyticsEndpoint = `${latitudeApiUrl}/events/server-event`
  fetch(analyticsEndpoint, {
    method: 'POST',
    body: JSON.stringify({
      eventName,
      experienceName: 'voyage',
      repoName: 'voyage-web',
      variation,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export default sendServerEvent
