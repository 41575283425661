import { useLocation } from 'react-router-dom'

function Account() {
  // Ideally this should check if someone is logged in or not, and spit them to the appropriate place
  const location = useLocation()
  const accountManagement =
    location.pathname.indexOf('login') > 0 ||
    location.pathname.indexOf('join') > 0 ||
    location.pathname.indexOf('forgotPassword') > 0 ||
    location.pathname.indexOf('resetPassword') > 0
  if (!accountManagement) window.location.replace('/account/login')
  return null
}

export default Account
